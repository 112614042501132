import { mapState, mapGetters, mapActions } from 'vuex';
import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
	name: 'so-user-account',
	template: '#so-user-account-template',
	delimiters: ['[[', ']]'],
	props: {
		contactId: {
			type: String,
			default: '',
		},
		matchRequirementsTabs: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			apiNamespace: 'account',
			errorMessage: '',
			loadedUser: false,
			loadingUser: false,
			status: {},
		};
	},
	computed: {
		...mapState('purchaser', {
			loadedMatchRequirements: 'loadedMatchRequirements',
		}),

		...mapGetters('misc', {
			activeTab: 'activeTab',
		}),

		/**
		 * Whether to display the holiday notice form
		 */
		showHolidayNotice() {
			return !isEmpty(this.status) && (this.status.IsLandlord || this.status.IsVendor);
		},

		/**
		 * Whether to display the vendor's properties
		 */
		showUserProperties() {
			return !isEmpty(this.status) && (this.status.IsLandlord || this.status.IsVendor);
		},
	},
	created() {
		this.getUserStatus();
	},
	methods: {
		...mapActions('purchaser', {
			loadAvailableFeatures: 'loadAvailableFeatures',
			loadRequirements: 'loadRequirements',
		}),

		/**
		 * Get the current user's status from Stand Out
		 */
		getUserStatus() {
			const url = getApiUrl('user-status', this.apiNamespace);

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url) && !isEmpty(this.contactId)) {
				this.loadingUser = true;
				this.loadedUser = false;

				axios
					.get(url, {
						params: {
							contact_id: this.contactId,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.status = data.user_status;
						}

						this.loadingUser = false;
						this.loadedUser = true;
					})
					.catch(error => {
						this.loadingUser = false;
						this.loadedUser = false;

						console.error(url, error);
					});
			}
		},
	},
	watch: {
		activeTab(activeTab) {
			// Load match requirements data as required
			if (!this.loadedMatchRequirements && this.matchRequirementsTabs.includes(activeTab)) {
				this.loadAvailableFeatures();
				this.loadRequirements(this.contactId);
			}
		},
	},
};
