export default {
	name: 'so-local-area-school',
	template: '#so-local-area-school-template',
	delimiters: ['[[', ']]'],
	props: {
		popup: {
			type: Boolean,
			default: true,
		},
		school: {
			type: Object,
			required: true,
		},
	},
};
