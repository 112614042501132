/**
 * string-helpers.js
 * String helper functions
 *
 * @author     Lewis Howles
 * @copyright  Internet Solutions Services Ltd
 */

/**
 * Transform a string and return something suitable for use as an HTML ID, with optional prefix
 * @param   {string}  string  The string to use as the ID's basis
 * @param   {string}  prefix  The prefix
 * @return  {string}          The ID string
 */
export function makeId(string, prefix) {
	string = string.toLowerCase();
	string = string.replace(/\W/g, '-');
	string = string.replace(/-+/g, '-');
	string = trim(string, '-');

	if (prefix !== undefined) {
		string = `${prefix}-${string}`;
	}

	return string;
}

/**
 * Trim the given mask from both ends of the given string
 * @param   {string}  string  The string to trim
 * @param   {string}  mask    The mask to remove
 * @return  {string}          The trimmed string
 */
export function trim(string, mask) {
	while (~mask.indexOf(string[0])) {
		string = string.slice(1);
	}

	while (~mask.indexOf(string[string.length - 1])) {
		string = string.slice(0, -1);
	}

	return string;
}
