export default {
	name: 'so-local-area-healthcare',
	template: '#so-local-area-healthcare-template',
	delimiters: ['[[', ']]'],
	props: {
		office: {
			type: Object,
			required: true,
		},
	},
};
