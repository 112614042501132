import { formatCurrency } from '~/resources/js/libs/number-helpers';

export default {
	name: 'app-currency',
	template: '#app-currency-template',
	delimiters: ['[[', ']]'],
	props: {
		currency: {
			type: String,
			default: 'GBP',
		},
		figure: {
			required: true,
		},
	},
	computed: {
		/**
		 * Format a number as a currency
		 */
		currencyString() {
			return formatCurrency(this.figure, this.currency);
		},
	},
};
