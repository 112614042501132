import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import { mapState } from 'vuex';
import { range } from '~/resources/js/libs/number-helpers';
import axios from 'axios';
import Language from '~/resources/js/libs/language';

export default {
	name: 'so-price-trends',
	template: '#so-price-trends-template',
	delimiters: ['[[', ']]'],
	props: {
		postcode: {
			type: String,
			required: true,
		},

		parentTab: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			apiNamespace: 'local-area',
			detachedTrends: [],
			errorMessage: '',
			flatTrends: [],
			loadedPriceTrends: false,
			loadingPriceTrends: false,
			metadata: {},
			semiDetachedTrends: [],
			showDetached: true,
			showFlats: true,
			showSemiDetached: true,
			showTerraced: true,
			terracedTrends: [],
			today: new Date(),
		};
	},
	computed: {
		...mapState('misc', {
			activeTab: 'activeTab',
		}),

		/**
		 * Determine whether there are any market stats to display
		 */
		hasPriceTrends() {
			return (
				this.loadedPriceTrends &&
				!this.loadingPriceTrends &&
				(this.flatTrends.length ||
					this.terracedTrends.length ||
					this.semiDetachedTrends.length ||
					this.detachedTrends.length)
			);
		},
	},
	methods: {
		/**
		 * Generate an SVG path for the given data
		 * @param  {array}  dataSet  The data set containing a sequence of points
		 */
		getPath(dataSet) {
			let path = [];

			dataSet.forEach(data => {
				path.push(`${data.x},${data.y}`);
			});

			return 'M' + path.join('L');
		},

		/**
		 * Get the year with the provided offset from today
		 * @param {int} offset  The offset of the current year
		 */
		getYear(offset) {
			return this.today.getFullYear() - offset;
		},

		/**
		 * Load price trends data
		 */
		loadPriceTrends() {
			const url = getApiUrl('price-trends', this.apiNamespace);

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url)) {
				this.loadingPriceTrends = true;
				this.loadedPriceTrends = false;

				axios
					.get(url, {
						params: {
							postcode: this.postcode,
							remove: 2,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.metadata = data.price_trends_metadata;
							this.flatTrends = data.flat_trends;
							this.terracedTrends = data.terraced_trends;
							this.semiDetachedTrends = data.semi_detached_trends;
							this.detachedTrends = data.detached_trends;
						} else {
							this.errorMessage = fallback(
								data.message,
								Language.localArea.priceTrends.errors.load
							);
						}

						this.loadingPriceTrends = false;
						this.loadedPriceTrends = true;
					})
					.catch(error => {
						if (error.response) {
							let data = error.response.data;

							this.errorMessage = fallback(
								data.message,
								Language.localArea.priceTrends.errors.load
							);
						} else {
							this.errorMessage = Language.localArea.priceTrends.errors.load;
						}

						this.loadingPriceTrends = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.localArea.priceTrends.errors.load;
			}
		},

		/**
		 * Create an array of numbers with the given start and end
		 * @param  {int}  start  The starting point
		 * @param  {int}  end  The ending point
		 */
		range(start, end) {
			return range(start, end);
		},
	},
	watch: {
		activeTab(activeTab) {
			if (activeTab === this.parentTab && !this.loadedPriceTrends && !this.loadingPriceTrends) {
				this.loadPriceTrends();
			}
		},
	},
};
