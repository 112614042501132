import { mapState, mapActions } from 'vuex';
import { isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';

export default {
	name: 'so-reactive',
	template: '#so-reactive-template',
	delimiters: ['[[', ']]'],
	props: {
		block: {
			type: Boolean,
			default: false,
		},
		buttonType: {
			type: String,
			default: 'button',
		},
		confirmMessage: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: Language.errors.saveFailed,
		},
		icon: {
			type: String,
			default: '',
		},
		initialLabel: {
			type: String,
			required: true,
		},
		link: {
			type: Boolean,
			default: false,
		},
		reactImmediately: {
			type: Boolean,
			default: false,
		},
		reactiveLabel: {
			type: String,
			required: true,
		},
		suffix: {
			type: Boolean,
			default: false,
		},
		type: {
			type: String,
			default: 'default',
			validator(value) {
				const allowedValues = ['default', 'danger', 'success'];
				const isAllowedValue = allowedValues.includes(value);

				console.assert(isAllowedValue, {
					message: `Invalid type. Type must be one of: [${allowedValues.join(', ')}].`,
					value,
				});

				return isAllowedValue;
			},
		},
	},
	data() {
		return {
			error: false,
			fields: null,
			formElement: null,
			processing: false,
		};
	},
	computed: {
		...mapState('misc', {
			reactive: 'reactive',
			svgPath: 'svgPath',
		}),

		/**
		 * Determine which button classes will apply
		 */
		buttonClasses() {
			return {
				'button--block': this.block,
				'button--danger': this.type == 'danger' && !this.link,
				'button--link--danger': this.type == 'danger' && this.link,
				'button--link--success': this.type == 'success' && this.link,
				'button--link': this.link,
				'button--success': this.type == 'success' && !this.link,
				'form__suffix--button': this.suffix,
				'inline-flex items-center justify-center': true,
				button: !this.link,
				form__suffix: this.suffix,
				processing: this.processing,
			};
		},

		/**
		 * Whether this button has an icon
		 */
		hasIcon() {
			return !isEmpty(this.icon);
		},

		/**
		 * Whether the current parent form contains tabs
		 */
		hasTabs() {
			return this.formElement !== null && this.formElement.querySelector('.tab-panel') !== null;
		},

		/**
		 * The path to the icon to display with this button
		 */
		iconPath() {
			if (!this.hasIcon) {
				return '';
			}

			return [this.svgPath, this.icon].join('#');
		},

		/**
		 * Whether this is a submit button
		 */
		isSubmit() {
			return this.buttonType == 'submit';
		},

		/**
		 * Determine the current button text
		 */
		label() {
			let label = this.initialLabel;

			if (this.error) {
				label = this.errorMessage;
			} else if (this.processing) {
				if (isEmpty(this.reactiveLabel)) {
					label = '';
				} else {
					label = `${this.reactiveLabel}\u2026`;
				}
			}

			return label;
		},
	},
	mounted() {
		if (this.isSubmit) {
			this.formElement = this.$el.closest('form');
			window.test = this.$el;

			if (this.formElement) {
				this.fields = this.formElement.elements;
			}
		}

		if (this.reactImmediately) {
			this.react();
		}
	},
	methods: {
		...mapActions('misc', {
			updateReactive: 'updateReactive',
		}),

		/**
		 * React to click
		 */
		react() {
			if (this.isSubmit && this.formElement && !this.formElement.checkValidity()) {
				// Let the user know that the page could not be saved
				this.setError();

				// Check whether we need to move to the first tab containing an error
				if (this.hasTabs) {
					// All relevant fields inside the current form
					let invalidFields = Object.values(this.fields).filter(field => !field.checkValidity());

					if (invalidFields.length) {
						let firstInvalidField = invalidFields[0];
						let panel = firstInvalidField.closest('.tab-panel');
						let index = parseInt(panel.getAttribute('data-index'));

						let validatedTabs = window.app.$refs.validatedTabs;

						if (validatedTabs) {
							validatedTabs.activateTab(index);
						}
					}
				}
			} else {
				if (this.type === 'danger' && !isEmpty(this.confirmMessage)) {
					var confirm = window.confirm(this.confirmMessage);
				}

				if (confirm || this.type != 'danger' || isEmpty(this.confirmMessage)) {
					// Provide user feedback that something is happening
					this.error = false;
					this.processing = true;

					// Store reactive state
					this.updateReactive(true);

					// Emit react event
					this.$emit('react');
				} else {
					this.resetButton();
				}
			}
		},

		/**
		 * Reset button state
		 */
		resetButton() {
			this.error = false;
			this.processing = false;
		},

		/**
		 * Set error state
		 */
		setError() {
			this.error = true;
			this.processing = false;

			// Reset button to original state
			setTimeout(() => {
				this.resetButton();
			}, 1000);
		},
	},
	watch: {
		reactive(reactive) {
			if (!reactive) {
				this.resetButton();
			}
		},
	},
};
