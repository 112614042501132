import { mapGetters, mapActions } from 'vuex';
import { responsive } from '~/resources/js/libs/mixins';
import { fallback, getApiUrl, getFirstItemInObject, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-agreements',
	template: '#so-agreements-template',
	delimiters: ['[[', ']]'],
	mixins: [responsive],
	props: {
		contactId: {
			type: String,
			required: true,
		},
		landlord: {
			type: Boolean,
			default: false,
		},
		parentTab: {
			type: String,
			default: '',
		},
		userStatus: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			apiNamespace: 'account/contact',
			errorMessage: '',
			loadedAgreements: false,
			loadingAgreements: false,
			properties: [],
			selectedAgreement: null,
			selectedProperty: null,
		};
	},
	computed: {
		...mapGetters('misc', {
			activeTab: 'activeTab',
		}),

		/**
		 * Whether the vendor has more than one property
		 */
		hasMultipleProperties() {
			return Object.keys(this.properties).length > 1;
		},

		/**
		 * Whether any property is currently selected
		 */
		propertyIsSelected() {
			return this.selectedProperty !== null;
		},

		/**
		 * Whether the selected property has any agreements
		 */
		selectedPropertyHasAgreements() {
			return (
				this.propertyIsSelected &&
				this.selectedProperty.Agreements &&
				this.selectedProperty.Agreements.length
			);
		},

		/**
		 * The list of agreements for the selected property
		 */
		selectedPropertyAgreements() {
			let agreements = [];

			if (this.propertyIsSelected && this.selectedProperty.Agreements) {
				agreements = this.selectedProperty.Agreements;
			}

			return agreements;
		},

		/**
		 * The name of the currently selected property
		 */
		selectedPropertyLabel() {
			let label = '';

			if (this.propertyIsSelected && !isEmpty(this.selectedProperty.PublicLabel)) {
				label = this.selectedProperty.PublicLabel;
			}

			return label;
		},
	},
	methods: {
		...mapActions('tenant', {
			updateSelectedTenantProperty: 'updateSelectedProperty',
		}),

		/**
		 * Disable the parent tab, if possible
		 */
		disableTab() {
			// Can't use $emit because so-tab uses slot
			if (typeof this.$parent.disable === 'function') {
				this.$parent.disable();
			}
		},

		/**
		 * Get applicable item classes for the current agreement
		 * @param   {object}  agreement  The agreement details
		 * @return  {string}             The agreement classes
		 */
		getAgreementClasses(agreement) {
			let classes = [];

			if (this.agreementIsSelected(agreement.AgreementID)) {
				classes.push('bg-green-100');
			} else if (this.contactId === agreement.GuarantorContactID) {
				classes.push('bg-blue-100');
			} else if (agreement.Current) {
				classes.push('bg-green-100 border-l-4 border-green-800');
			} else if (!agreement.Active) {
				classes.push('bg-red-100 border-l-4 border-red-800');
			} else {
				classes.push('bg-muted-background');
			}

			return classes.join(' ');
		},

		/**
		 * Determine whether a particular agreement is the selected agreement
		 * @param   {object}  agreementId  The agreement details
		 * @return  {bool}                 Whether this agreement is active
		 */
		agreementIsSelected(agreementId) {
			let agreementSelected = false;

			if (this.selectedAgreement !== null) {
				agreementSelected = agreementId === this.selectedAgreement.AgreementID;
			}

			return agreementSelected;
		},

		/**
		 * Check whether agreements should be loaded, and if so, do so
		 */
		checkLoadAgreements() {
			if (!isEmpty(this.userStatus) && !this.loadedAgreements) {
				if (this.userStatus.IsLandlord || this.userStatus.IsRenting) {
					this.loadAgreements();
				}
			}
		},

		/**
		 * Load the agreements for the current user
		 */
		loadAgreements() {
			const url = getApiUrl('agreements', this.apiNamespace);

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url)) {
				this.loadingAgreements = true;
				this.loadedAgreements = false;

				axios
					.get(url, {
						params: {
							contact_id: this.contactId,
							landlord: this.landlord,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.properties = data.properties;

							// If only one property present, select it
							if (Object.keys(this.properties).length === 1) {
								this.setSelectedProperty(getFirstItemInObject(this.properties));
							} else {
								// Always set the tenant property
								this.updateSelectedTenantProperty(getFirstItemInObject(this.properties));
							}
						} else {
							this.errorMessage = fallback(data.error, Language.account.agreements.errors.fail);
						}

						if (!Object.keys(this.properties).length) {
							// Disable this tab
							this.disableTab();
						}

						this.loadingAgreements = false;
						this.loadedAgreements = true;
					})
					.catch(error => {
						// Disable this tab
						this.disableTab();

						this.errorMessage = Language.account.agreements.errors.fail;
						this.loadingAgreements = false;
						this.loadedAgreements = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.account.agreements.errors.fail;
			}
		},

		/**
		 * Reset the selected property for re-selection
		 */
		resetSelectedProperty() {
			this.setSelectedProperty(null);
			this.setSelectedAgreement(null);
		},

		/**
		 * Set the currently selected agreement
		 * @param  {object}  agreement  The agreement to select
		 */
		setSelectedAgreement(agreement) {
			this.selectedAgreement = Object.freeze(agreement);
		},

		/**
		 * Set the currently selected property
		 * @param  {object}  property  The property to select
		 */
		setSelectedProperty(property) {
			this.selectedProperty = Object.freeze(property);

			if (!this.landlord) {
				this.updateSelectedTenantProperty(property);
			}
		},
	},
	watch: {
		userStatus: {
			deep: true,
			handler() {
				if (this.activeTab === this.parentTab) {
					this.checkLoadAgreements();
				}
			},
		},

		activeTab(activeTab) {
			if (activeTab === this.parentTab) {
				this.checkLoadAgreements();
			}
		},
	},
};
