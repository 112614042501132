import { head as _head, isEmpty as _isEmpty, value as _value } from 'lodash';
import { trim } from '~/resources/js/libs/string-helpers';

/**
 * helpers.js
 * Global helper functions
 *
 * @author     Lewis Howles
 * @copyright  Internet Solutions Services Ltd
 */

/**
 * Set any existing properties that match url parameters
 * @param  {object}  urlVars       The current URL variables
 * @param  {object}  instance      The data instance to apply parameters to
 * @param  {object}  replacements  Optional name-replacements
 * @param  {string}  property      Optional sub-property of instance
 */
export function applyUrlVars(urlVars, instance, replacements, property) {
	if (property !== undefined) {
		instance = instance[property];
	}

	for (let urlVar in urlVars) {
		let property = urlVar;

		// If a matching replacement is defined, update the property name
		if (replacements !== undefined) {
			let replacement = replacements[property];

			if (replacement !== undefined) {
				property = replacement;
			}
		}

		if (instance.hasOwnProperty(property)) {
			if (Array.isArray(urlVars[urlVar])) {
				instance[property] = urlVars[urlVar].map(item =>
					decodeURIComponent(item.replace(/\+/g, '%20'))
				);
			} else {
				instance[property] = decodeURIComponent(urlVars[urlVar].replace(/\+/g, '%20'));
			}
		}
	}
}

/**
 * Check for cookie existence
 * @param  {string}  cookieName  The cookie name to check
 */
export function checkCookie(cookieName) {
	const cookies = document.cookie.split(';').map(cookie => trim(cookie.trim(), '='));
	const matchingCookies = cookies.filter(item => item == cookieName);

	return matchingCookies.length;
}

/**
 * Expire a cookie of the given key
 */
export function expireCookie(name) {
	setCookie(name, -1);
}

/**
 * Check that a given value is present, and if not, fall back to a default
 * @param   {mixed}  value     The value to check
 * @param   {mixed}  fallback  The fallback value
 * @return  {mixed}            The value, or its fallback
 */
export function fallback(value, fallback) {
	if (value !== undefined) {
		return value;
	} else {
		return fallback;
	}
}

/**
 * Build a path to an API file
 * @param   {string}  file  The filename
 * @param   {string}  path  The partial file path
 * @return  {string}        The complete API url
 */
export function getApiUrl(file, path) {
	let url = [`${window.location.protocol}//${window.location.hostname}`];

	if (window.location.href.includes('/dev/')) {
		url.push('dev');
	}

	url.push('assets/api');

	if (!isEmpty(path)) {
		url.push(path);
	}

	if (!isEmpty(file)) {
		url.push(file);
	}

	return `${url.join('/')}.php`;
}

/**
 * Get the first item in an array-like object
 * @param   {object}  object  The object to traverse
 * @return  {mixed}           The value of the first item
 */
export function getFirstItemInObject(object) {
	let key = Object.keys(object);
	key = _head(key);

	let output = null;

	if (object.hasOwnProperty(key)) {
		output = object[key];
	}

	return output;
}

/**
 * Retrieve current url parameters as an object
 * @return  {object}  The current url parameters
 */
export function getUrlVars() {
	let vars = {};

	window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
		key = decodeURIComponent(key);
		value = decodeURIComponent(value.replace(/\+/g, ' '));

		if (key.includes('[]')) {
			key = key.replace('[]', '');

			if (vars[key] === undefined) {
				vars[key] = [];
			}

			vars[key].push(value);
		} else {
			vars[key] = value;
		}
	});

	return vars;
}

/**
 * Check if an input is defined, null and empty
 * @param   {input}  input  The input to compare
 * @return  {bool}          Whether the input is empty
 */
export function isEmpty(input) {
	if (typeof input == 'number') {
		return input == 0;
	} else if (typeof input == 'string') {
		return _isEmpty(input.trim());
	} else {
		return _isEmpty(input);
	}
}

/**
 * Check if an input is defined and not null
 * @param   {input}  input  The input to compare
 * @return  {bool}          Whether the input is set
 */
export function isset(input) {
	return input !== undefined && input !== null;
}

/**
 * Load a script and call a callback function
 * @param  {string}    src       The script src
 * @param  {function}  callback  The callback function on load
 */
export function loadScript(src, callback) {
	if (!isEmpty(src)) {
		let script = document.createElement('script');

		script.src = src;

		if (callback) {
			script.onload = () => callback(script);
		}

		document.head.append(script);
	}
}

/**
 * Set a cookie
 * @param  {string}  name   The name of the cookie
 * @param  {int}     days   The number of days before the cookie expires
 * @param  {string}  value  The value of the cookie
 */
export function setCookie(name, days, value) {
	let expires = '';

	if (days) {
		let date = new Date();

		date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);

		expires = `; expires=${date.toUTCString()}`;
	}

	document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

/**
 * Replace an existing url parameter with the given new value, or add it to the current URL
 * @param  {string}  param  The parameter to update
 * @param  {string}  value  The new parameter value
 */
export function updateUrlVar(param, value) {
	let url = window.location.href;

	let regex = new RegExp(`([?&])${param}=.*?(&|#|$)(.*)`, 'gi');
	let hash;

	if (regex.test(url)) {
		if (value !== undefined && value !== null) return url.replace(regex, `$1${param}=${value}$2$3`);
		else {
			hash = url.split('#');
			url = hash[0].replace(regex, '$1$3').replace(/(&|\?)$/, '');

			if (hash[1] !== undefined && hash[1] !== null) {
				url += `#${hash[1]}`;
			}

			return url;
		}
	} else {
		if (value !== undefined && value !== null) {
			let separator = url.includes('?') ? '&' : '?';

			hash = url.split('#');
			url = `${hash[0]}${separator}${param}=${value}`;

			if (hash[1] !== undefined && hash[1] !== null) {
				url += `#${hash[1]}`;
			}
		}

		return url;
	}
}
