import { mapGetters, mapState, mapActions } from 'vuex';
import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-match-requirements',
	template: '#so-match-requirements-template',
	delimiters: ['[[', ']]'],
	props: {
		contactId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			activeRequirement: null,
			apiNamespace: 'account/match',
			creatingOrEditing: false,
			errorMessage: '',
			form: {
				matchContractType: 0,
				matchDistance: 1,
				matchFeatures: [],
				matchMaximumPrice: '',
				matchMinimumBedrooms: 0,
				matchMinimumPrice: '',
				matchName: '',
				matchNewBuild: 0,
				matchPropertyStyle: '',
				matchPropertyType: '',
			},
			matchActive: true,
			matchId: '',
			matchLatitudeMax: '',
			matchLatitudeMin: '',
			matchLocationLabel: '',
			matchLocationValue: '',
			matchLongitudeMax: '',
			matchLongitudeMin: '',
			successMessage: '',
		};
	},
	computed: {
		...mapGetters('misc', {
			activeTab: 'activeTab',
		}),

		...mapState('purchaser', {
			availableFeatures: 'availableFeatures',
			loadingMatchRequirements: 'loadingMatchRequirements',
			matchRequirements: 'matchRequirements',
		}),

		/**
		 * Whether the currently entered match requirements are valid
		 */
		valid() {
			return (
				!isEmpty(this.form.matchName) &&
				(!isEmpty(this.matchLocationValue) || !isEmpty(this.matchLatitudeMin))
			);
		},
	},
	methods: {
		...mapActions('misc', {
			updateReactive: 'updateReactive',
			updateActiveTab: 'updateActiveTab',
		}),

		...mapActions('purchaser', {
			loadRequirements: 'loadRequirements',
		}),

		/**
		 * Display form to create match requirements
		 */
		createRequirements() {
			this.resetForm();

			this.creatingOrEditing = true;
		},

		/**
		 * Delete the current active requirements
		 */
		deleteActiveRequirement() {
			this.deleteRequirement(this.matchId);
		},

		/**
		 * Delete requirements by Match ID
		 * @param  {int}  matchId  The selected Match ID
		 */
		deleteRequirement(matchId) {
			const url = getApiUrl('match-requirement-delete', this.apiNamespace);

			if (!isEmpty(url)) {
				this.errorMessage = '';
				this.successMessage = '';

				axios
					.get(url, {
						params: {
							contact_id: this.contactId,
							match_id: matchId,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							this.successMessage = Language.account.requirements.deleted;

							this.resetForm();

							// Re-load requirement list
							this.loadRequirements();
						} else {
							this.errorMessage = fallback(data.error, Language.account.requirements.errors.delete);
						}

						this.updateReactive(false);
					})
					.catch(error => {
						this.updateReactive(false);

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.account.requirements.errors.delete;

				this.updateReactive(false);
			}
		},

		/**
		 * Load match requirement for editing
		 * @param  {int}  index  The index of the requirement to edit
		 */
		loadRequirement(index) {
			const url = getApiUrl('match-requirement-edit', this.apiNamespace);

			this.activeRequirement = this.matchRequirements[index];

			this.errorMessage = '';
			this.successMessage = '';

			if (!isEmpty(url) && this.activeRequirement) {
				axios
					.get(url, {
						params: {
							contact_id: this.contactId,
							match_id: this.activeRequirement.MatchNumber,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							let requirement = data.requirement;

							this.creatingOrEditing = true;
							this.form.matchContractType = requirement.BuyRent;
							this.form.matchDistance = requirement.Radius;
							this.form.matchMaximumPrice = requirement.MaxPrice;
							this.form.matchMinimumBedrooms = requirement.MinBedrooms;
							this.form.matchMinimumPrice = requirement.MinPrice;
							this.form.matchName = requirement.MatchName;
							this.form.matchNewBuild = requirement.NewBuildOnly;
							this.form.matchPropertyStyle = requirement.Style;
							this.form.matchPropertyType = requirement.Type;
							this.matchActive = requirement.Active;
							this.matchId = requirement.MatchNumber;
							this.matchLocationLabel = requirement.MatchArea;
							this.matchLocationValue =
								typeof requirement.MatchAreaValue === 'object'
									? JSON.stringify(requirement.MatchAreaValue)
									: requirement.MatchAreaValue;
							this.matchLatitudeMax = requirement.MaxLatitude;
							this.matchLatitudeMin = requirement.MinLatitude;
							this.matchLongitudeMax = requirement.MaxLongitude;
							this.matchLongitudeMin = requirement.MinLongitude;

							// Stored as comma separated IDs, we want to
							// split selected features and determine
							// which of the master list apply
							let selectedFeatures = '';
							if (requirement.RequiredFeatures != null){
								selectedFeatures = requirement.RequiredFeatures.split(',').map(number =>
									parseInt(number)
								);	
							}

							this.form.matchFeatures = this.availableFeatures.filter(feature =>
								selectedFeatures.includes(feature.FeatureID)
							);
						} else {
							this.errorMessage = fallback(data.error, Language.account.requirements.errors.edit);
						}

						this.updateReactive(false);
					})
					.catch(error => {
						this.errorMessage = Language.account.requirements.errors.edit;

						this.updateReactive(false);

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.account.requirements.errors.edit;

				this.updateReactive(false);
			}
		},

		/**
		 * Reset form to initial state
		 */
		resetForm() {
			this.creatingOrEditing = false;
			this.form.matchContractType = 0;
			this.form.matchDistance = 1;
			this.form.matchFeatures = [];
			this.form.matchMaximumPrice = '';
			this.form.matchMinimumBedrooms = 0;
			this.form.matchMinimumPrice = '';
			this.form.matchName = '';
			this.form.matchNewBuild = 0;
			this.form.matchPropertyStyle = '';
			this.form.matchPropertyType = '';
			this.matchActive = true;
			this.matchId = '';
			this.matchLatitudeMax = '';
			this.matchLatitudeMin = '';
			this.matchLocationLabel = '';
			this.matchLocationValue = '';
			this.matchLongitudeMax = '';
			this.matchLongitudeMin = '';
		},

		/**
		 * Store, or update current match requirement
		 */
		updateRequirement() {
			const url = getApiUrl('match-requirement-update', this.apiNamespace);

			if (!isEmpty(url)) {
				this.errorMessage = '';
				this.successMessage = '';

				axios
					.get(url, {
						params: {
							contact_id: this.contactId,
							match_area: this.matchLocationLabel,
							match_contract_type: this.form.matchContractType,
							match_distance: this.form.matchDistance,
							match_id: this.matchId,
							match_latitude_max: this.matchLatitudeMax,
							match_latitude_min: this.matchLatitudeMin,
							match_longitude_max: this.matchLongitudeMax,
							match_longitude_min: this.matchLongitudeMin,
							match_maximum_price: this.form.matchMaximumPrice,
							match_minimum_bedrooms: this.form.matchMinimumBedrooms,
							match_minimum_price: this.form.matchMinimumPrice,
							match_name: this.form.matchName,
							match_new_build: this.form.matchNewBuild,
							match_property_style: this.form.matchPropertyStyle,
							match_property_type: this.form.matchPropertyType,
							match_features: this.form.matchFeatures.map(feature => feature.FeatureID).join(','),
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							this.successMessage = Language.account.requirements.saved;

							this.resetForm();

							// Re-load requirement list
							this.loadRequirements();

							// Switch to live matches
							this.updateActiveTab('live-matches');
						} else {
							this.errorMessage = fallback(data.error, Language.account.requirements.errors.update);
						}

						this.updateReactive(false);
					})
					.catch(error => {
						this.updateReactive(false);

						this.errorMessage = Language.account.requirements.errors.update;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.account.requirements.errors.update;

				this.updateReactive(false);
			}
		},
	},
	watch: {
		/**
		 * Set min and max latitude and longitude when updating location
		 */
		matchLocationValue(location) {
			if (typeof location === 'string') {
				if (isEmpty(location)) {
					this.matchLatitudeMax = '';
					this.matchLatitudeMin = '';
					this.matchLongitudeMax = '';
					this.matchLongitudeMin = '';

					return true;
				} else {
					location = JSON.parse(location);
				}
			}

			this.matchLatitudeMax = location.latitude.max;
			this.matchLatitudeMin = location.latitude.min;
			this.matchLongitudeMax = location.longitude.max;
			this.matchLongitudeMin = location.longitude.min;
		},
	},
};
