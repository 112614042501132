import { mapState } from 'vuex';
import { isEmpty } from '~/resources/js/libs/helpers';
import { makeId } from '~/resources/js/libs/string-helpers';
import axios from 'axios';

export default {
	name: 'so-tab',
	template: '#so-tab-template',
	delimiters: ['[[', ']]'],
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		disabledTitle: {
			type: String,
			default: '',
		},
		header: {
			type: String,
			required: true,
		},
		icon: {
			type: String,
			default: '',
		},
		initialActive: {
			type: Boolean,
			default: false,
		},
		initialId: {
			type: String,
			default: '',
		},
		pill: {
			type: String,
			default: '',
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		url: {
			type: String,
		},
	},
	data() {
		return {
			accordion: false,
			active: this.initialActive,
			enabled: true,
			error: false,
			id: this.initialId,
			initialised: false,
			tabPanelContent: null,
		};
	},
	computed: {
		...mapState('misc', {
			svgPath: 'svgPath',
			urlVars: 'urlVars',
		}),

		/**
		 * The path to the tab icon
		 */
		iconPath() {
			return [this.svgPath, this.icon].join('#');
		},

		/**
		 * This tab's unique ID
		 */
		tabId() {
			return `tab--${this.id}`;
		},

		/**
		 * This tab's panel ID
		 */
		tabPanelId() {
			return `tab-panel--${this.id}`;
		},
	},
	created() {
		if (this.disabled) {
			this.disable();
		}

		if (this.$parent.accordion) {
			this.accordion = true;
		}
	},
	mounted() {
		// Create ID based on passed parameter, or label
		if (isEmpty(this.initialId)) {
			this.id = makeId(this.header);
		}

		if (!this.active) {
			// Set active if 'tab' url parameter matches the ID
			if (
				this.urlVars !== undefined &&
				this.urlVars.tab !== undefined &&
				this.urlVars.tab.toLowerCase() == this.id
			) {
				this.active = true;
			}

			// Determine whether this tab should activate itself via data attribute
			if (!this.active && this.$el.querySelectorAll('[data-hello]').length) {
				this.active = true;
			}

			// Determine whether this tab should activate itself if it contains an alert
			if (!this.active) {
				this.$children.forEach(child => {
					if (
						child.$options.name == 'so-alert' &&
						child.$props.type == 'error' &&
						child.$el.offsetParent !== null
					) {
						this.active = true;
					}
				});
			}

			if (!this.active) {
				let errorAlerts = this.$el.querySelectorAll('.alert--error');

				if (errorAlerts.length) {
					for (let i = 0; i < errorAlerts.length; ++i) {
						if (!this.active) {
							let alert = errorAlerts[i];

							if (alert.offsetParent !== null) {
								this.active = true;
							}
						}
					}
				}
			}
		}

		// Store tab panel content if URL is defined
		if (this.url !== undefined) {
			this.tabPanelContent = this.$el.querySelector('.tab-panel__content');
		}

		// Register tab with parent tabs
		this.$parent.registerTab(this);
	},
	beforeDestroy() {
		this.$parent.removeTab(this);

		document.removeEventListener('keydown');
	},
	methods: {
		/**
		 * When pressing certain keys, activate the corresponding sibling
		 * @param  {int}     currentIndex  The current active tab's index
		 * @param  {object}  event         The original event data
		 */
		activateSiblingTab(currentIndex, event) {
			this.$parent.activateSiblingTab(currentIndex, event);
		},

		/**
		 * Activate a given tab
		 * @param  {int}   index        The tab index
		 * @param  {bool}  programatic  Whether this activation was caused programatically
		 * @param  {bool}  fromStore    Whether this activation was caused by a Store change
		 */
		activateTab(index, programatic, fromStore) {
			this.$parent.activateTab(index, programatic, fromStore);
		},

		/**
		 * Disable this tab
		 */
		disable() {
			this.enabled = false;
		},

		/**
		 * Enable this tab
		 */
		enable() {
			this.enabled = true;
		},

		/**
		 * Get the title to display for a tab, if relevant
		 */
		getTabTitle() {
			this.$parent.getTabTitle(this);
		},
	},
	watch: {
		/**
		 * When a tab is activated, initialise if required
		 */
		active(active) {
			const url = this.url;

			if (active && url !== undefined && !this.initialised) {
				// Fetch addresses from API
				axios
					.get(url)
					.then(({ data }) => {
						this.tabPanelContent.innerHTML = data;
					})
					.catch(error => {
						this.error = true;

						console.error(url, error);
					});

				this.initialised = true;
			}
		},

		/**
		 * Disable a tab if called upon
		 */
		disabled(disabled) {
			if (disabled) {
				this.disable();
			} else {
				this.enable();
			}
		},
	},
};
