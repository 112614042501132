import axios from 'axios';
import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';

export default {
	namespaced: true,
	state: {
		availableFeatures: [],
		loadedMatchRequirements: false,
		loadingMatchRequirements: false,
		matchRequirements: [],
	},
	actions: {
		/**
		 * Load the available features for the current agent
		 */
		loadAvailableFeatures: ({ commit }) => {
			const url = getApiUrl('standout-property-features', 'property/data');

			if (!isEmpty(url)) {
				axios
					.get(url)
					.then(({ data }) => {
						if (data && data.status === 'success') {
							commit('setAvailableFeatures', Object.freeze(data.features));
						}
					})
					.catch(error => {
						console.error(url, error);
					});
			}
		},

		/**
		 * Load match requirements for current user
		 */
		loadRequirements: ({ commit }, contactId) => {
			const url = getApiUrl('match-requirements', 'account/match');

			// Load options
			if (!isEmpty(url)) {
				commit('setLoadingRequirements', true);
				commit('setLoadedRequirements', false);

				axios
					.get(url, {
						params: {
							contact_id: contactId,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							commit('setMatchRequirements', Object.freeze(data.requirements));
						}

						commit('setLoadingRequirements', false);
						commit('setLoadedRequirements', true);
					})
					.catch(error => {
						commit('setLoadingRequirements', false);
						commit('setLoadedRequirements', true);

						console.error(url, error);
					});
			}
		},
	},
	mutations: {
		/**
		 * Set the available features for match requirements
		 */
		setAvailableFeatures: (state, features) => {
			state.availableFeatures = features;
		},

		/**
		 * Set the current state for loaded requirements
		 */
		setLoadedRequirements: (state, value) => {
			state.loadedMatchRequirements = value;
		},

		/**
		 * Set the current state for loading requirements
		 */
		setLoadingRequirements: (state, value) => {
			state.loadingMatchRequirements = value;
		},

		/**
		 * Set the current match requirements list
		 */
		setMatchRequirements: (state, requirements) => {
			state.matchRequirements = requirements;
		},
	},
};
