import { head } from 'lodash';

export default {
	name: 'so-select',
	template: '#so-select-template',
	delimiters: ['[[', ']]'],
	props: {
		fieldId: {
			type: String,
			required: true,
		},
		fieldName: {
			type: String,
			required: true,
		},
		hiddenInputType: {
			type: String,
			default: 'hidden',
		},
		initialOptions: {
			type: String,
			default: '',
		},
		initialValue: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			options: JSON.parse(this.initialOptions),
			value: this.initialValue,
		};
	},
	computed: {
		hiddenInputDisplay() {
			if (this.hiddenInputType !== 'hidden') {
				return 'none';
			}

			return '';
		},
	},
	created() {
		if (this.value === '' && this.options.length) {
			this.value = head(this.options).value;
		}
	},
};
