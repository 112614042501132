import { checkCookie, expireCookie, setCookie } from '~/resources/js/libs/helpers';
import { makeId } from '~/resources/js/libs/string-helpers';

export default {
	name: 'so-cookie-toggle',
	template: '#so-cookie-toggle-template',
	delimiters: ['[[', ']]'],
	props: {
		cookieName: {
			type: String,
			required: true,
		},
		description: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			accept: false,
			initialised: false,
			initialLoad: false,
		};
	},
	computed: {
		/**
		 * Generate a name for a denial version of this cookie
		 */
		denyCookieName() {
			return `${this.cookieName}-deny`;
		},

		/**
		 * Generate an ID for this toggle switch
		 */
		id() {
			return makeId(this.cookieName, 'cookie-input');
		},
	},
	created() {
		// We want to keep track of whether the checkboxes were
		// activated by default, or manually
		if (checkCookie(this.cookieName)) {
			// If we've already set an acceptance cookie, reflect that internally
			this.accept = true;
		} else if (!checkCookie(this.denyCookieName)) {
			// Otherwise, set an 'initially accepted' state
			this.accept = true;

			this.setInitialCookie();
		}

		// We don't want to immediately alter anything on load, so wait a specified period
		// before allowing further changes to take effect
		setTimeout(() => {
			this.initialised = true;
		}, 500);
	},
	methods: {
		/**
		 * Set the cookie on first load, while allowing the visitor to simply remove it
		 */
		setInitialCookie() {
			setCookie(this.cookieName, 365);
		},
	},
	watch: {
		/**
		 * Keep track of denial and acceptance of cookies, so that they can be
		 * turned on for an initial load, but that the visitor can still easily
		 * disable them should they wish
		 *
		 * NOTE: Firefox seems to, as of version 69, will set a
		 * maximum expires time of 7 days for cookies
		 */
		accept(accept) {
			if (this.initialised) {
				if (accept) {
					expireCookie(this.denyCookieName);
					setCookie(this.cookieName, 365);
				} else {
					expireCookie(this.cookieName);
					setCookie(this.denyCookieName, 365);
				}

				this.$emit('changed');
			}
		},
	},
};
