import { mapState, mapActions } from 'vuex';

export default {
	name: 'so-privacy-placeholder',
	template: '#so-privacy-placeholder-template',
	delimiters: ['[[', ']]'],
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	computed: {
		...mapState('misc', {
			cookieNoticeActive: 'cookieNoticeActive',
		}),

		content() {
			if (this.type == 'map') {
				return 'Please enable Maps in Privacy & Cookies settings to view this map.';
			}
		},
	},
	methods: {
		...mapActions('cookie', {
			updateCookieNoticeActive: 'updateCookieNoticeActive',
		}),

		/**
		 * Activate the global cookie notice in order to change settings
		 */
		activateCookieNotice() {
			this.updateCookieNoticeActive(true);
		},
	},
};
