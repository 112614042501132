import { fallback, getApiUrl, isEmpty, isset } from '~/resources/js/libs/helpers';
import { responsive } from '~/resources/js/libs/mixins';
import Language from '~/resources/js/libs/language';
import axios from 'axios';
import moment from 'moment';

export default {
	name: 'so-agreement-transactions',
	template: '#so-agreement-transactions-template',
	delimiters: ['[[', ']]'],
	mixins: [responsive],
	props: {
		agreement: {
			type: Object,
			default() {
				return {};
			},
		},
		landlord: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			apiNamespace: 'account/contact',
			currentEndDate: null,
			currentStartDate: null,
			errorMessage: '',
			loadedTransactions: false,
			loadingTransactions: false,
			previousAgreementId: '',
			selectedEndDate: null,
			selectedStartDate: null,
			transactions: [],
		};
	},
	computed: {
		/**
		 * Determine whether an agreement is selected
		 */
		agreementSelected() {
			return !isEmpty(this.agreement);
		},

		/**
		 * Determine the reference for the selected agreement, if present
		 */
		selectedAgreementReference() {
			let reference = '';

			if (isset(this.agreement) && !isEmpty(this.agreement.AgreementReference)) {
				reference = this.agreement.AgreementReference;
			}

			return reference;
		},
	},
	methods: {
		/**
		 * Load transactions for the currently selected agreement
		 * @param  {bool}  matchDatesToAgreement  Whether to match the selected dates to the agreement start and end dates
		 */
		loadTransactions(matchDatesToAgreement) {
			const url = getApiUrl('transactions', this.apiNamespace);

			matchDatesToAgreement = fallback(matchDatesToAgreement, true);

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url)) {
				this.transactions = [];
				this.loadingTransactions = true;
				this.loadedTransactions = false;

				if (matchDatesToAgreement) {
					this.selectedStartDate = this.agreement.TenancyStartDate;
					this.currentStartDate = this.agreement.TenancyStartDate;
					this.selectedEndDate = this.agreement.TenancyEndDate;
					this.currentEndDate = this.agreement.TenancyEndDate;
				} else {
					this.currentStartDate = this.selectedStartDate;
					this.currentEndDate = this.selectedEndDate;
				}

				axios
					.get(url, {
						params: {
							agreement_id: this.agreement.AgreementID,
							start_date: this.selectedStartDate,
							end_date: this.selectedEndDate,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.transactions = data.payments;
						} else {
							this.errorMessage = fallback(data.error, Language.account.transactions.errors.fail);
						}

						this.loadingTransactions = false;
						this.loadedTransactions = true;
					})
					.catch(error => {
						this.errorMessage = Language.account.transactions.errors.fail;
						this.loadingTransactions = false;
						this.loadedTransactions = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.account.transactions.errors.fail;
			}
		},

		/**
		 * Load transactions for the current agreement, using the user-selected date range
		 */
		loadTransactionsForDateRange() {
			this.loadTransactions(false);
		},

		/**
		 * Reset the current state to initial values
		 */
		resetState() {
			this.currentEndDate = null;
			this.currentStartDate = null;
			this.errorMessage = '';
			this.loadedTransactions = false;
			this.previousAgreementId = '';
			this.selectedEndDate = null;
			this.selectedStartDate = null;
			this.transactions = [];
		},
	},
	watch: {
		/**
		 * Load transactions when the selected agreement changes
		 */
		agreement(agreement) {
			// Only load transactions if the agreement ID exists, and isn't the one we've just seen
			if (agreement === null) {
				this.resetState();
			} else if (
				!isEmpty(agreement.AgreementID) &&
				agreement.AgreementID !== this.previousAgreementId
			) {
				// Load transactions for the new agreement, matching the dates to the agreement dates
				this.loadTransactions(true);

				this.previousAgreementId = agreement.AgreementID;
			}
		},

		/**
		 * Detect date changes and update Pikaday
		 */
		selectedStartDate(selectedStartDate) {
			let element = this.$refs['start-date'];

			element.pikadayInstance.setMoment(moment(selectedStartDate, 'DD/MM/YYYY'));
		},

		/**
		 * Detect date changes and update Pikaday
		 */
		selectedEndDate(selectedEndDate) {
			let element = this.$refs['end-date'];

			window.instance = element.pikadayInstance;

			element.pikadayInstance.setMoment(moment(selectedEndDate, 'DD/MM/YYYY'));
		},
	},
};
