/**
 * Language.js
 * Language strings
 *
 * @author     Lewis Howles
 * @copyright  Internet Solutions Services Ltd
 */

export default {
	account: {
		requirements: {
			saved: 'Match requirements successfully saved',
			deleted: 'Match requirements successfully deleted',

			errors: {
				delete: 'Sorry, something went wrong while trying to delete these requirements',
				edit: 'Sorry, something went wrong while trying to edit these requirements',
				features: 'Sorry, something went wrong while trying to load available features',
				list: 'Sorry, something went wrong while trying to load requirements',
				update: 'Sorry, something went wrong while trying to update these requirements',
			},
		},

		agreements: {
			errors: {
				fail: 'Sorry, something went wrong while trying to load agreements',
			},
		},

		transactions: {
			errors: {
				fail: 'Sorry, we could not load transactions for this agreement at this time',
			},
		},
	},

	appraisal: {
		errors: {
			offices: 'No offices could be found at this time. Please try again later.',
		},
	},

	errors: {
		saveFailed: 'Could not save',
	},

	localArea: {
		marketStats: {
			errors: {
				load: 'Sorry, we could not load market stats at this time. Please try again later.',
			},
		},
		priceTrends: {
			errors: {
				load: 'Sorry, we could not load price trends at this time. Please try again later.',
			},
		},
		soldProperties: {
			errors: {
				load:
					'Sorry, we could not load recently sold properties at this time. Please try again later.',
			},
		},
	},

	pleaseWait: 'Please wait&hellip;',

	postcode: {
		errors: {
			fail: 'Sorry, we could not find any results for the postcode entered',
		},
	},

	property: {
		favourite: {
			logIn: 'Log in to save',
			remove: 'Remove',
			removing: 'Removing',
			save: 'Save',
			saving: 'Saving',

			errors: {
				removeFailed: 'Could not remove',
				saveFailed: 'Could not save',
			},
		},
	},

	repairs: {
		list: {
			errors: {
				load: 'Sorry, we could not load repairs at this time. Please try again later.',
			},
		},
		report: {
			errors: {
				missingDetails: 'Please enter details for the repair.',
				save: 'Sorry, we could not save this repair request at this time. Please try again later.',
			},
			success: {
				save: 'Repair reported successfully.',
			},
		},
	},

	valuation: {
		errors: {
			fail: 'Sorry, we could not determine a valuation for the information entered',
		},
	},
};
