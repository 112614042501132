export default {
	name: 'so-property-photographs',
	delimiters: ['[[', ']]'],
	props: {
		defaultView: {
			default: 'medium',
		},
	},
	data() {
		return {
			view: this.defaultView,
		};
	},
	computed: {
		/**
		 * Generate the appropriate class for the selected view
		 */
		viewClass() {
			return `property-photographs__list--${this.view}`;
		},
	},
};
