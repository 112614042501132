/**
 * Join items with the given separator
 * @param   {array}   items      The items to join
 * @param   {string}  separator  The separator to use
 * @return  {string}             The joined items
 */
export default (items, separator) => {
	if (Array.isArray(items)) {
		if (separator === undefined || typeof separator !== 'string') {
			separator = ',';
		}

		return items.join(separator);
	} else {
		return items;
	}
};
