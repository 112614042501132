import { range } from 'lodash';

export default {
	name: 'so-pagination',
	template: '#so-pagination-template',
	delimiters: ['[[', ']]'],
	props: {
		initialPage: {
			type: Number,
			default: 1,
		},
		perPage: {
			type: Number,
			default: 10,
		},
		pluralVerb: {
			type: String,
			default: 'items',
		},
		singularVerb: {
			type: String,
			default: 'item',
		},
		total: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			currentPage: this.initialPage,
		};
	},
	computed: {
		/**
		 * The array of page numbers local to the current page
		 */
		pageNumbers() {
			let pageNumbers = [];

			// Aim for five pages, with the current page in the centre, if possible
			if (this.totalPages < 5) {
				pageNumbers = range(1, this.totalPages + 1);
			} else if (this.currentPage <= 3) {
				pageNumbers = range(1, 6);
			} else if (this.currentPage >= this.totalPages - 2) {
				pageNumbers = range(this.totalPages - 4, this.totalPages + 1);
			} else {
				pageNumbers = range(this.currentPage - 2, this.currentPage + 3);
			}

			return pageNumbers;
		},

		/**
		 * The maximum item number on the current page
		 */
		pageMax() {
			let max = 0;

			if (this.total < this.perPage) {
				max = this.total;
			} else {
				max = this.pageMin + this.perPage - 1;
			}

			if (max > this.total) {
				max = this.total;
			}

			return max;
		},

		/**
		 * The minimum item number on the current page
		 */
		pageMin() {
			return (this.currentPage - 1) * this.perPage + 1;
		},

		/**
		 * The number of pages required for the
		 * current total and items per page
		 */
		totalPages() {
			return Math.ceil(this.total / this.perPage);
		},
	},
	methods: {
		/**
		 * Update parent with currently selected page
		 * @param  {int}  page  The chosen page
		 */
		updateSelectedPage(page) {
			this.currentPage = page;

			this.$emit('update:initialPage', page);
		},
	},
};
