import { mapGetters } from 'vuex';

export default {
	name: 'so-local-area-mini',
	template: '#so-local-area-mini-template',
	delimiters: ['[[', ']]'],
	props: {},
	data() {
		return {
			errorMessage: '',
		};
	},
	computed: {
		...mapGetters('localArea', {
			nearestPrimarySchool: 'nearestPrimarySchool',
			nearestSecondarySchool: 'nearestSecondarySchool',
			nearestTrainStation: 'nearestTrainStation',
		}),

		hasLocalInformation() {
			return this.nearestPrimarySchool || this.nearestSecondarySchool || this.nearestTrainStation;
		},
	},
};
