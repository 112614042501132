import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
	name: 'so-autocomplete',
	template: '#so-autocomplete-template',
	delimiters: ['[[', ']]'],
	props: {
		additionalClasses: {
			type: String,
			default: '',
		},
		help: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			required: true,
		},
		initialSelected: {
			type: String,
			default: '',
		},
		initialQuery: {
			type: String,
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
		latitude: {
			type: String,
			required: true,
		},
		longitude: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		queryName: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		requiredSeeming: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			apiNamespace: 'location',
			fetching: false,
			previousQuery: this.initialQuery,
			query: this.initialQuery,
			selected: this.initialSelected,
			suggestions: [],
			timeout: null,
		};
	},
	methods: {
		/**
		 * Mark the selected suggestion as chosen
		 * @param  {object}  suggestion  The selected suggestion
		 */
		chooseSuggestion(suggestion) {
			this.previousQuery = suggestion.label;
			this.query = suggestion.label;
			this.selected = suggestion.value;
			this.suggestions = [];
		},

		/**
		 * Fetch autocomplete suggestions from given query
		 */
		fetchSuggestions() {
			const url = getApiUrl('location-search', this.apiNamespace);

			clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				// Fetch locations
				if (
					!isEmpty(url) &&
					!isEmpty(this.query) &&
					this.query.length > 2 &&
					this.query != this.previousQuery
				) {
					// Store previous query
					this.previousQuery = this.query;

					// Reset state
					this.resetState();

					this.fetching = true;

					axios
						.get(url, {
							params: {
								query: this.query,
								latitude: this.latitude,
								longitude: this.longitude,
							},
						})
						.then(({ data }) => {
							let suggestions = data.suggestions;

							for (var i = 0; i < suggestions.length; i++) {
								let suggestion = suggestions[i];

								if (typeof suggestion.value === 'object') {
									suggestion.value = JSON.stringify(suggestion.value);
								}
							}

							this.suggestions = data.suggestions;
							this.fetched = true;
							this.fetching = false;
						})
						.catch(error => {
							this.fetched = true;
							this.fetching = false;

							console.error(url, error);
						});
				} else if (isEmpty(this.query)) {
					this.selected = '';
				}
			}, 500);
		},

		/**
		 * Reset search state
		 */
		resetState() {
			this.suggestions = [];
			this.fetched = false;
			this.selected = '';
		},
	},
	watch: {
		// Allow changing of a property to update current query value
		initialQuery(value) {
			this.query = value;
		},

		// Allow changing of a property to update current selected value
		initialSelected(value) {
			this.selected = value;
		},

		// Change parent data when editing value
		selected(value) {
			this.$emit('update:initialSelected', value);
		},

		// Change parent data when editing value
		query(value) {
			this.$emit('update:initialQuery', value);
		},
	},
};
