import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';
import Language from '~/resources/js/libs/language';

export default {
	name: 'so-report-repair',
	template: '#so-report-repair-template',
	delimiters: ['[[', ']]'],
	props: {
		propertyId: {
			type: String,
		},
	},
	data() {
		return {
			apiNamespace: 'account/tenant',
			errorMessage: '',
			form: {},
			formActive: false,
			saving: false,
			successMessage: '',
		};
	},
	created() {
		this.resetForm();
	},
	methods: {
		closeForm() {
			this.formActive = false;

			this.resetMessages();
		},

		openForm() {
			this.formActive = true;
		},

		resetForm() {
			this.$set(this, 'form', {
				details: '',
				notes: '',
			});
		},

		resetMessages() {
			this.sucecssMessage = '';
			this.errorMessage = '';
		},

		saveRepair() {
			this.resetMessages();

			if (!isEmpty(this.form.details)) {
				const url = getApiUrl('report-repair', this.apiNamespace);

				if (!isEmpty(url)) {
					const errorMessage = Language.repairs.report.errors.save;
					const successMessage = Language.repairs.report.success.save;

					this.saving = true;

					axios
						.post(url, {
							...this.form,
							property_id: this.propertyId,
						})
						.then(({ data }) => {
							if (data && data.status === 'success') {
								this.closeForm();
								this.resetForm();

								this.$emit('repair:added');

								this.successMessage = fallback(data.message, successMessage);

								setTimeout(() => {
									this.successMessage = '';
								}, 3000);
							} else {
								this.errorMessage = fallback(data.message, errorMessage);
							}

							this.saving = false;

							this.$refs.submit.processing = false;
						})
						.catch(error => {
							if (error.response) {
								let data = error.response.data;

								this.errorMessage = fallback(data.message, errorMessage);
							} else {
								this.errorMessage = errorMessage;
							}

							this.saving = false;

							this.$refs.submit.processing = false;

							console.error(url, error);
						});
				} else {
					this.errorMessage = errorMessage;

					this.$refs.submit.processing = false;
				}
			} else {
				this.errorMessage = Language.repairs.report.errors.missingDetails;

				this.$refs.submit.processing = false;
			}
		},
	},
};
