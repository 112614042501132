import { mapGetters, mapState, mapActions } from 'vuex';
import { responsive } from '~/resources/js/libs/mixins';
import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
	name: 'so-live-matches',
	template: '#so-live-matches-template',
	delimiters: ['[[', ']]'],
	mixins: [responsive],
	props: {
		contactId: {
			type: String,
			required: true,
		},
		parentTab: {
			type: String,
			default: '',
		},
		propertiesPerPage: {
			type: Number,
			default: 10,
		},
	},
	data() {
		return {
			apiNamespace: 'account/match',
			currentPage: 1,
			fetchingMatches: true,
			loadedMatches: false,
			properties: [],
			propertyCount: 0,
		};
	},
	computed: {
		...mapGetters('misc', {
			activeTab: 'activeTab',
		}),

		...mapState('purchaser', {
			matchRequirements: 'matchRequirements',
		}),
	},
	methods: {
		...mapActions('misc', {
			updateReactive: 'updateReactive',
		}),

		/**
		 * Generate a valid URL to a property details page, given the root URL and property reference
		 * @param   {string}  reference  The property reference to link to
		 * @return  {string}             The property details link
		 */
		buildPropertyUrl(reference) {
			// let url = [`${window.location.protocol} // ${window.location.hostname}`];
			let url = [`${window.location.protocol}`];
			if (window.location.href.includes('/dev/')) {
				url.push('dev');
			}

			url.push(`match-property-id-${reference}`);

			return url.join('/');
		},

		/**
		 * Determine if the given Media URL is valid
		 * @param   {string}  mediaUrl  The Media URL to check
		 * @return  {bool}              Whether the photograph is deemed to be valid
		 */
		isValidPhotograph(mediaUrl) {
			return !isEmpty(mediaUrl) && !mediaUrl.includes('waiting.jpg');
		},

		/**
		 * Update current live match list
		 */
		loadLiveMatches() {
			const url = getApiUrl('live-matches', this.apiNamespace);

			if (!isEmpty(url)) {
				this.fetchingMatches = true;
				this.loadedMatches = false;

				axios
					.get(url, {
						params: {
							contact_id: this.contactId,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							this.properties = data.properties;
							this.currentPage = data.pagination.current_page;
							this.propertyCount = data.pagination.property_count;
						} else {
							this.properties = [];
						}

						this.fetchingMatches = false;
						this.loadedMatches = true;

						this.updateReactive(false);
					})
					.catch(error => {
						console.error(url, error);

						this.fetchingMatches = false;

						this.updateReactive(false);
					});
			}
		},
	},
	watch: {
		/**
		 * Refresh live matches when switching tab
		 */
		activeTab(activeTab) {
			if (activeTab == this.parentTab && !this.loadedMatches) {
				this.loadLiveMatches();
			}
		},

		/**
		 * Fetch live matches when page number changes
		 */
		currentPage() {
			this.loadLiveMatches();
		},

		/**
		 * Fetch live matches when match requirements change
		 */
		matchRequirements() {
			this.loadLiveMatches();
		},
	},
};
