import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
	namespaced: true,
	state: {
		outlets: {
			schools: {
				items: [],
				loaded: false,
				loading: false,
				radius: 4,
			},
			transit: {
				rail: {
					items: [],
					loaded: false,
					loading: false,
					radius: 10,
					label: 'Railway stations',
				},
				metro: {
					items: [],
					loaded: false,
					loading: false,
					radius: 10,
					label: 'Metro stations',
				},
				air: {
					items: [],
					loaded: false,
					loading: false,
					radius: 25,
					label: 'Airports',
				},
				bus: {
					items: [],
					loaded: false,
					loading: false,
					radius: 2,
					label: 'Bus stops',
				},
				taxi: {
					items: [],
					loaded: false,
					loading: false,
					radius: 5,
					label: 'Taxi ranks',
				},
				ferry: {
					items: [],
					loaded: false,
					loading: false,
					radius: 25,
					label: 'Ferry ports',
				},
			},
			healthcare: {
				gp: {
					items: [],
					loaded: false,
					loading: false,
					radius: 10,
					label: 'GP surgeries',
				},
				hospital: {
					items: [],
					loaded: false,
					loading: false,
					radius: 25,
					label: 'Hospitals',
				},
				dentist: {
					items: [],
					loaded: false,
					loading: false,
					radius: 10,
					label: 'Dentists',
				},
				optician: {
					items: [],
					loaded: false,
					loading: false,
					radius: 10,
					label: 'Opticians',
				},
			},
		},
	},
	getters: {
		nearestPrimarySchool: state => {
			// Find all primary schools
			let primarySchools = state.outlets.schools.items.filter(school => school.key == 'primary');

			if (!primarySchools.length) {
				return null;
			}

			return primarySchools[0];
		},

		nearestSecondarySchool: state => {
			// Find all secondary schools
			let secondarySchools = state.outlets.schools.items.filter(
				school => school.key == 'secondary'
			);

			if (!secondarySchools.length) {
				return null;
			}

			return secondarySchools[0];
		},

		nearestTrainStation: state => {
			let trainStations = state.outlets.transit.rail.items;

			if (!trainStations.length) {
				return null;
			}

			return trainStations[0];
		},
	},
	actions: {
		/**
		 * Load local area data for all defined categories
		 */
		loadAllLocalAreaData({ state, dispatch }, postcode) {
			const outlets = state.outlets;

			Object.keys(outlets).forEach(categoryName => {
				let categoryObject = outlets[categoryName];

				if (categoryObject.items === undefined) {
					// This item has children
					Object.keys(categoryObject).forEach(typeName => {
						const typeObject = categoryObject[typeName];

						if (typeObject.items !== undefined) {
							dispatch('loadLocalAreaData', {
								category: categoryName,
								type: typeName,
								radius: typeObject.radius,
								postcode: postcode,
							});
						}
					});
				} else {
					dispatch('loadLocalAreaData', {
						category: categoryName,
						radius: categoryObject.radius,
						postcode: postcode,
					});
				}
			});
		},

		/**
		 * Load a particular set of local area data
		 */
		loadLocalAreaData: ({ commit }, { category, type, radius, postcode }) => {
			const url = getApiUrl(category, 'local-area');

			if (!isEmpty(url)) {
				commit('setLocalAreaLoadingState', { category, type, loading: true, loaded: false });

				axios
					.get(url, {
						params: {
							postcode: postcode,
							radius: radius,
							type: type,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							commit('setLocalAreaData', { category, type, outlets: Object.freeze(data.outlets) });
						}

						commit('setLocalAreaLoadingState', { category, type, loading: false, loaded: true });
					})
					.catch(error => {
						commit('setLocalAreaLoadingState', { category, type, loading: false, loaded: true });

						console.error(url, error);
					});
			}
		},
	},
	mutations: {
		/**
		 * Set the current outlets
		 */
		setLocalAreaData: (state, { category, type, outlets }) => {
			let item = state.outlets[category];

			if (type !== undefined) {
				item = item[type];
			}

			item.items = outlets;
		},

		/**
		 * Set the current loading state
		 */
		setLocalAreaLoadingState(state, { category, type, loading, loaded }) {
			let item = state.outlets[category];

			if (type !== undefined) {
				item = item[type];
			}

			item.loading = loading;
			item.loaded = loaded;
		},
	},
};
