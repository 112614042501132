import { mapActions } from 'vuex';

export default {
	name: 'so-toggle-component',
	props: {
		componentName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			componentIsVisible: false,
		};
	},
	methods: {
		...mapActions('misc', {
			toggleComponent: 'toggleComponent',
		}),

		/**
		 * Toggle the target component's state
		 */
		toggle() {
			this.componentIsVisible = !this.componentIsVisible;

			this.toggleComponent({
				componentName: this.componentName,
				visible: this.componentIsVisible,
			});
		},
	},
};
