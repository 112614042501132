import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-save-property',
	template: '#so-save-property-template',
	delimiters: ['[[', ']]'],
	props: {
		chainId: {
			type: String,
			required: true,
		},
		initialSaved: {
			type: Boolean,
			default: false,
		},
		reference: {
			type: String,
			required: true,
		},
		userId: {
			type: String,
			required: true,
		},
		viewSaved: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			apiNamespace: 'property',
			error: false,
			loading: false,
			removed: false,
			saved: this.initialSaved,
		};
	},
	computed: {
		/**
		 * The button text to show depending on current state
		 */
		label() {
			let label = '';

			// Processing takes precedence to show immediate feedback
			if (this.loading) {
				if (this.saved) {
					label = Language.property.favourite.removing;
				} else {
					label = Language.property.favourite.saving;
				}
			} else if (this.error) {
				if (this.saved) {
					label = Language.property.favourite.errors.removeFailed;
				} else {
					label = Language.property.favourite.errors.saveFailed;
				}
			} else {
				if (this.viewSaved || this.saved) {
					label = Language.property.favourite.remove;
				} else if (this.userId === '') {
					label = Language.property.favourite.logIn;
				} else {
					label = Language.property.favourite.save;
				}
			}

			return label;
		},
	},
	methods: {
		/**
		 * Update the status of the current property based on action taken
		 */
		updateStatus() {
			const url = getApiUrl('property-save', this.apiNamespace);

			// Reset error message
			this.error = false;

			if (!isEmpty(url)) {
				this.loading = true;

				axios
					.post(url, {
						ChainID: this.chainId,
						Reference: this.reference,
						Save: !this.saved,
						UserID: this.userId,
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							// Update UI
							if (this.saved) {
								this.saved = false;
								this.removed = true;
							} else {
								this.saved = true;
								this.removed = false;
							}
						} else {
							this.error = true;
						}

						this.loading = false;
					})
					.catch(error => {
						this.error = true;
						this.loading = false;

						console.error(url, error);
					});
			} else {
				this.error = true;
			}
		},
	},
};
