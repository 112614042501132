import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import { makeId } from '~/resources/js/libs/string-helpers';
import axios from 'axios';

export default {
	name: 'so-types-styles',
	template: '#so-types-styles-template',
	delimiters: ['[[', ']]'],
	props: {
		additionalClasses: {
			type: String,
			default: '',
		},
		baseId: {
			type: String,
			default: 'property-type',
		},
		feedValue: {
			default: '',
		},
		function: {
			type: String,
			required: true,
		},
		idPrefix: {
			type: String,
			default: '',
		},
		initialValue: {
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
		name: {
			type: String,
			default: 'type',
		},
	},
	data() {
		return {
			fetching: false,
			options: [],
			value: this.initialValue,
		};
	},
	computed: {
		/**
		 * Generate an ID for this select, with optional prefix
		 */
		id() {
			return makeId(this.baseId, this.idPrefix);
		},
	},
	created() {
		this.fetchOptions();
	},
	methods: {
		/**
		 * Fetch options from given url with current feed value
		 */
		fetchOptions() {
			const url = getApiUrl(this.function, 'property/data');

			// Fetch options
			if (!isEmpty(url) && !isEmpty(this.feedValue)) {
				this.fetching = true;

				this.options = [];

				axios
					.get(url, {
						params: {
							feed: this.feedValue,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							this.options = data.options;
						}

						this.fetching = false;
					})
					.catch(error => {
						this.fetching = false;

						console.error(url, error);
					});
			}
		},
	},
	watch: {
		feedValue() {
			this.fetchOptions();
		},

		// Allow changing of a property to update current type value
		initialValue(value) {
			this.value = value;
		},

		// Change parent data when editing value
		value(value) {
			this.$emit('update:initialValue', value);
		},
	},
};
