export default {
	name: 'so-repair',
	template: '#so-repair-template',
	delimiters: ['[[', ']]'],
	props: {
		first: {
			type: Boolean,
			default: false,
		},
		repair: {
			type: Object,
			required: true,
		},
		last: {
			type: Boolean,
			default: false,
		},
	},
};
