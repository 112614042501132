import { find, findIndex } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { isEmpty, updateUrlVar } from '~/resources/js/libs/helpers';
import Vue from 'vue';

export default {
	name: 'so-tabs',
	template: '#so-tabs-template',
	delimiters: ['[[', ']]'],
	props: {
		accordion: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			currentTab: null,
			isSmoothScrollSupported: false,
			tabs: [],
		};
	},
	computed: {
		...mapGetters('misc', {
			activeTab: 'activeTab',
			activeTabScroll: 'activeTabScroll',
		}),

		/**
		 * Allow a tab with a prop of showActions = false
		 * to hide the actions for a form that follows a tabset
		 */
		showActionsClass() {
			let actionsClass = '';
			let activeTab = find(this.tabs, tab => {
				return tab.$data.active;
			});

			if (activeTab && !activeTab.$props.showActions) {
				actionsClass = 'tabset--no-actions';
			}

			return actionsClass;
		},
	},
	mounted() {
		// Refresh lazy load
		window.LazyLoadInstance.update();

		// Listen to popstate events and, if necessary, update active tab
		window.onpopstate = event => {
			// Only update tab position if the event related to this set of tabs
			if (event.state.uid == this._uid) {
				this.activateTab(event.state.index, true);
			}
		};

		// Determine if smooth scrolling is supported
		this.isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
	},
	methods: {
		...mapActions('misc', {
			updateActiveTab: 'updateActiveTab',
		}),

		/**
		 * When pressing certain keys, activate the corresponding sibling
		 * @param  {int}     currentIndex  The current active tab's index
		 * @param  {object}  event         The original event data
		 */
		activateSiblingTab(currentIndex, event) {
			if (
				(event.code == 'Tab' && !event.shiftKey) ||
				['ArrowDown', 'ArrowRight'].includes(event.code)
			) {
				// Tab, right or down arrow pressed, proceed to next tab
				if (currentIndex < this.tabs.length - 1) {
					this.activateTab(this.findNextActiveTab());
				}
			} else if (
				(event.code == 'Tab' && event.shiftKey) ||
				['ArrowUp', 'ArrowLeft'].includes(event.code)
			) {
				// Shift-tab, left or up arrow pressed, proceed to previous tab
				if (currentIndex > 0) {
					this.activateTab(this.findPreviousActiveTab());
				}
			} else if (event.code == 'Home') {
				this.activateTab(0);
			} else if (event.code == 'End') {
				this.activateTab(this.tabs.length - 1);
			}
		},

		/**
		 * Activate a given tab
		 * @param  {int}   index        The tab index
		 * @param  {bool}  programatic  Whether this activation was caused programatically
		 * @param  {bool}  fromStore    Whether this activation was caused by a Store change
		 */
		activateTab(index, programatic, fromStore) {
			// Set selected tab as active
			let tab = this.tabs[index];

			if (tab.enabled) {
				// Determine whether this activation was programatic
				programatic = programatic === undefined ? false : programatic;
				fromStore = fromStore === undefined ? false : fromStore;

				if (tab && !tab.disabled) {
					if (index == 'first') {
						index = 0;
					} else if (index == 'last') {
						index = this.tabs.length - 1;
					}

					this.tabs.forEach((tab, id) => {
						tab.active = id === index;
					});

					// Update URL if this tab wasn't activated programattically and isn't the same as current target
					if ((this.activeTab != tab.id || fromStore) && !programatic) {
						var url = updateUrlVar('tab', tab.id);
						window.history.pushState({ uid: this._uid, index: index }, '', url);
					}

					// Update the Store with the newly active tab
					if (this.activeTab != tab.id) {
						this.updateActiveTab(tab.id);
					}

					// Update local current tab
					this.currentTab = tab.id;

					// Scroll tab into view
					let tabElement = document.getElementById(tab.tabId);

					if (tabElement && ((!programatic && this.activeTabScroll) || this.accordion)) {
						Vue.nextTick(() => {
							if (this.isSmoothScrollSupported) {
								tabElement.scrollIntoView({ behavior: 'smooth' });
							} else {
								tabElement.scrollIntoView(true);
							}
						});
					}
				}
			}
		},

		/**
		 * Ensure that at least one tab is active
		 */
		ensureActiveTab() {
			// Find the active tab, or set it to the first tab
			var activeTab = 0;

			this.tabs.forEach((tab, index) => {
				if (tab.active && tab.enabled) {
					activeTab = index;
				}
			});

			this.activateTab(activeTab, true);
		},

		/**
		 * Find the next tab that is not hidden
		 */
		findNextActiveTab() {
			// Find the currently active tab
			let activeTabIndex = findIndex(this.tabs, { id: this.activeTab });
			let returnIndex = activeTabIndex;

			if (activeTabIndex > -1) {
				for (let i = activeTabIndex + 1; i < this.tabs.length; i++) {
					if (!this.tabs[i].hidden) {
						returnIndex = i;
						break;
					}
				}

				return returnIndex;
			} else {
				return 0;
			}
		},

		/**
		 * Find the next tab that is not hidden
		 */
		findPreviousActiveTab() {
			// Find the currently active tab
			let activeTabIndex = findIndex(this.tabs, { id: this.activeTab });
			let returnIndex = activeTabIndex;

			if (activeTabIndex > -1) {
				for (let i = activeTabIndex - 1; i >= 0; i--) {
					if (!this.tabs[i].hidden) {
						returnIndex = i;
						break;
					}
				}

				return returnIndex;
			} else {
				return 0;
			}
		},

		/**
		 * Get the title to display for a tab, if relevant
		 * @param  {object}  tab  Tab data
		 */
		getTabTitle(tab) {
			let title = '';

			if (!tab.enabled && !isEmpty(tab.disabledTitle)) {
				title = tab.disabledTitle;
			}

			return title;
		},

		/**
		 * Register a child tab with the parent
		 * @param  {object}  tab  Tab data
		 */
		registerTab(tab) {
			// Initialise this tab with tabs
			tab.index = this.tabs.length;
			tab.animations = tab.$el.querySelectorAll('.animate');

			this.tabs.push(tab);
			this.ensureActiveTab();
		},

		/**
		 * Destroy a tab
		 * @param  {object}  tab  Tab data
		 */
		removeTab(tab) {
			// Remove this tab
			this.tabs.$remove(tab);
			this.ensureActiveTab();
		},
	},
	watch: {
		/**
		 * Monitor changes to the globally active tab, and if found, activate it
		 */
		activeTab(tabId) {
			if (tabId != this.currentTab) {
				var activeTab = find(this.tabs, tab => {
					return tab.$data.id == tabId;
				});

				if (activeTab) {
					this.activateTab(activeTab.index, false, true);
				}
			}
		},
	},
};
