export default {
	name: 'so-property-results',
	delimiters: ['[[', ']]'],
	props: {
		initialView: {
			default: 'grid',
		},
		staticView: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			view: this.initialView,
		};
	},
	created() {
		this.setInitialView();
	},
	computed: {
		/**
		 * Generate the appropriate class for the selected view
		 */
		propertiesClass() {
			return `properties--${this.view}`;
		},
	},
	methods: {
		setInitialView() {
			// If there is a static view, take that first
			if (this.staticView !== '') {
				return this.staticView;
			}

			let savedView = localStorage.getItem('property-view');

			if (savedView !== null) {
				this.view = savedView;
			}
		},
	},
	watch: {
		view(value) {
			localStorage.setItem('property-view', value);

			// Update lazyLoad when updating view, in case any new images are revealed
			setTimeout(() => {
				window.LazyLoadInstance.update();
			}, 50);
		},
	},
};
