export default {
	name: 'so-alert',
	template: '#so-alert-template',
	delimiters: ['[[', ']]'],
	props: {
		additionalClasses: {
			type: String,
			default: '',
		},
		createClasses: {
			type: String,
			default: '',
		},
		destroyClasses: {
			type: String,
			default: 'animate fadeOutUp',
		},
		type: {
			type: String,
			default: 'info',
			validator(value) {
				const allowedValues = ['info', 'success', 'warning', 'error'];
				const isAllowedValue = allowedValues.includes(value);

				console.assert(isAllowedValue, {
					message: `Invalid type. Type must be one of: [${allowedValues.join(', ')}].`,
					value,
				});

				return isAllowedValue;
			},
		},
	},
	data() {
		return {
			closed: false,
			show: true,
			timeOut: 3000,
		};
	},
	computed: {
		/**
		 * Currently appropriate classes based on status of alert
		 */
		classes() {
			if (this.closed) {
				return [this.typeClass, this.additionalClasses, this.destroyClasses];
			}

			return [this.createClasses, this.typeClass, this.additionalClasses];
		},

		/**
		 * Determine the type class based on alert type
		 */
		typeClass() {
			return {
				'alert--error': this.type === 'error',
				'alert--warning': this.type === 'warning',
				'alert--success': this.type === 'success',
				'alert--info': this.type === 'info',
			};
		},
	},
};
