import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import { mapState } from 'vuex';
import axios from 'axios';
import Language from '~/resources/js/libs/language';

export default {
	name: 'so-repairs-list',
	template: '#so-repairs-list-template',
	delimiters: ['[[', ']]'],
	props: {
		contactId: {
			type: String,
			required: true,
		},
		parentTab: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			apiNamespace: 'account/tenant',
			errorMessage: '',
			loadedRepairs: false,
			loadingRepairs: false,
			repairs: [],
		};
	},
	computed: {
		...mapState('misc', {
			activeTab: 'activeTab',
		}),

		...mapState('tenant', {
			selectedProperty: 'selectedProperty',
		}),

		pendingRepairs() {
			if (!this.repairs.length) {
				return [];
			}

			return this.repairs.filter(repair => !repair.Resolved);
		},

		resolvedRepairs() {
			if (!this.repairs.length) {
				return [];
			}

			return this.repairs.filter(repair => repair.Resolved);
		},

		selectedPropertyId() {
			if (!this.selectedProperty) {
				return '';
			}

			return this.selectedProperty.PropertyID;
		},

		selectedPropertyLabel() {
			if (!this.selectedProperty) {
				return '';
			}

			return this.selectedProperty.PublicLabel;
		},
	},
	methods: {
		loadRepairs() {
			const url = getApiUrl('repair-list', this.apiNamespace);
			const errorMessage = Language.repairs.list.errors.load;

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url)) {
				this.loadingRepairs = true;
				this.loadedRepairs = false;

				axios
					.get(url, {
						params: {
							property_id: this.selectedPropertyId,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.repairs = data.repairs;
						} else {
							this.errorMessage = fallback(data.message, errorMessage);
						}

						this.loadingRepairs = false;
						this.loadedRepairs = true;
					})
					.catch(error => {
						if (error.response) {
							let data = error.response.data;

							this.errorMessage = fallback(data.message, errorMessage);
						} else {
							this.errorMessage = errorMessage;
						}

						this.loadingRepairs = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = errorMessage;
			}
		},
	},
	watch: {
		activeTab(activeTab) {
			if (
				this.selectedProperty &&
				activeTab === this.parentTab &&
				!this.loadedRepairs &&
				!this.loadingRepairs
			) {
				this.loadRepairs();
			}
		},

		selectedProperty() {
			if (!this.loadedRepairs && !this.loadingRepairs) {
				this.loadRepairs();
			}
		},
	},
};
