import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import { mapState } from 'vuex';
import axios from 'axios';
import Language from '~/resources/js/libs/language';

export default {
	name: 'so-sold-properties',
	template: '#so-sold-properties-template',
	delimiters: ['[[', ']]'],
	props: {
		postcode: {
			type: String,
			required: true,
		},

		parentTab: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			apiNamespace: 'local-area',
			errorMessage: '',
			loadedSoldProperties: false,
			loadingSoldProperties: false,
			soldProperties: [],
		};
	},
	computed: {
		...mapState('misc', {
			activeTab: 'activeTab',
		}),

		/**
		 * Determine whether there are any market stats to display
		 */
		hasSoldProperties() {
			return this.loadedSoldProperties && !this.loadingSoldProperties && this.soldProperties.length;
		},
	},
	methods: {
		/**
		 * Load sold properties data
		 */
		loadSoldProperties() {
			const url = getApiUrl('sold-properties', this.apiNamespace);

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url)) {
				this.loadingSoldProperties = true;
				this.loadedSoldProperties = false;

				axios
					.get(url, {
						params: {
							postcode: this.postcode,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.soldProperties = data.sold_properties;
						} else {
							this.errorMessage = fallback(
								data.message,
								Language.localArea.soldProperties.errors.load
							);
						}

						this.loadingSoldProperties = false;
						this.loadedSoldProperties = true;
					})
					.catch(error => {
						if (error.response) {
							let data = error.response.data;

							this.errorMessage = fallback(
								data.message,
								Language.localArea.soldProperties.errors.load
							);
						} else {
							this.errorMessage = Language.localArea.soldProperties.errors.load;
						}

						this.loadingSoldProperties = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.localArea.soldProperties.errors.load;
			}
		},
	},
	watch: {
		activeTab(activeTab) {
			if (
				activeTab === this.parentTab &&
				!this.loadedSoldProperties &&
				!this.loadingSoldProperties
			) {
				this.loadSoldProperties();
			}
		},
	},
};
