export default {
	name: 'so-epc',
	template: '#so-epc-template',
	delimiters: ['[[', ']]'],
	props: {
		barHeight: {
			type: Number,
			default: 55,
		},
		eec: {
			default: 1,
		},
		eep: {
			default: 1,
		},
	},
	computed: {
		eecColour() {
			return this.getColour(this.eec);
		},

		eecTransform() {
			return `translate(0 ${this.getOffset(this.eec)})`;
		},

		eepColour() {
			return this.getColour(this.eep);
		},

		eepTransform() {
			return `translate(0 ${this.getOffset(this.eep)})`;
		},
	},
	methods: {
		/**
		 * Determine the appropriate colour for a given figure
		 * @param  {int}  figure  The current figure
		 */
		getColour(figure) {
			let colour;

			switch (true) {
				case figure >= 1 && figure <= 20:
					colour = '#E21F3A';
					break;
				case figure >= 21 && figure <= 38:
					colour = '#ED8026';
					break;
				case figure >= 39 && figure <= 54:
					colour = '#F3A869';
					break;
				case figure >= 55 && figure <= 68:
					colour = '#F6CC15';
					break;
				case figure >= 69 && figure <= 80:
					colour = '#8CBC41';
					break;
				case figure >= 81 && figure <= 91:
					colour = '#2BA45B';
					break;
				default:
					colour = '#107D58';
					break;
			}

			return colour;
		},

		/**
		 * Determine the appropriate offset for a given figure
		 * @param  {int}  figure  The current figure
		 */
		getOffset(figure) {
			let offset;

			switch (true) {
				case figure >= 1 && figure <= 20:
					offset = 6;
					break;
				case figure >= 21 && figure <= 38:
					offset = 5;
					break;
				case figure >= 39 && figure <= 54:
					offset = 4;
					break;
				case figure >= 55 && figure <= 68:
					offset = 3;
					break;
				case figure >= 69 && figure <= 80:
					offset = 2;
					break;
				case figure >= 81 && figure <= 91:
					offset = 1;
					break;
				default:
					offset = 0;
					break;
			}

			return offset * this.barHeight;
		},
	},
};
