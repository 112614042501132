import { mapState } from 'vuex';
import { applyUrlVars, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-property-search',
	delimiters: ['[[', ']]'],
	props: {
		auction: {
			type: Boolean,
			default: false,
		},
		buying: {
			type: Boolean,
			default: true,
		},
		categoriseAreas: {
			type: Boolean,
			default: true,
		},
		commercial: {
			type: Boolean,
			default: true,
		},
		currency: {
			type: String,
			default: 'GBP',
		},
		defaultContract: {
			type: String,
			default: 'buying',
		},
		includePropertyAreas: {
			type: Boolean,
			default: false,
		},
		includePropertyTypes: {
			type: Boolean,
			default: false,
		},
		initialUnderOffer: {
			type: Boolean,
			default: false,
		},
		renting: {
			type: Boolean,
			default: true,
		},
		residential: {
			type: Boolean,
			default: true,
		},
		resultsOrder: {
			type: String,
			default: 'HiLo',
		},
	},
	data() {
		return {
			advanced: true,
			advancedClosedLabel: 'Show',
			advancedOpenLabel: 'Hide',
			apiNamespace: 'property/search',

			closedIcon: '↓',
			closedLabel: 'Refine',
			open: false,
			openIcon: '↑',
			openLabel: 'Hide',

			areas: {
				list: [],
				loaded: false,
				loading: false,
				open: false,
			},

			propertyTypes: {
				cache: {},
				loaded: false,
				loading: false,
				list: [],
			},

			contract: this.defaultContract,
			ddlArea: [],
			ddlBedrooms: 0,
			ddlPayMax: '',
			ddlPayMin: '',
			ddlRadius: 0,
			ddlResultsOrder: this.resultsOrder,
			ddlUnderOffer: this.initialUnderOffer,
			propertyClass: 'residential',
			PropType: '',
			txtQuickSearch: '',

			priceOptions: {
				buying: {},
				loaded: false,
				loading: false,
				renting: {},
			},
		};
	},
	computed: {
		...mapState('misc', {
			svgPath: 'svgPath',
			urlVars: 'urlVars',
		}),

		/**
		 * The label appropriate to the current advanced options visibility
		 */
		advancedVerb() {
			return this.advanced ? this.advancedOpenLabel : this.advancedClosedLabel;
		},

		/**
		 * Determine if all areas are selected
		 */
		allAreasSelected() {
			let selectedAreas = this.areas.list.filter(area => area.checked);

			return selectedAreas.length === this.areas.list.length;
		},

		/**
		 * Determine the most apporpriate label for the currently selected areas
		 */
		areasSelectedLabel() {
			if (this.allAreasSelected) {
				return 'All';
			}

			let areas = this.selectedAreaLabels;

			if (!areas.length) {
				return 'All';
			}

			if (areas.length < 4) {
				return areas.join(' / ');
			}

			return `${areas.length} selected`;
		},

		/**
		 * Group incoming areas by their category
		 */
		categorisedAreas() {
			if (!this.categoriseAreas) {
				return {};
			}

			let categories = {};

			this.areas.list.forEach(area => {
				// Initialise category
				if (categories[area.category_alias] === undefined) {
					categories[area.category_alias] = {
						list: [],
						name: area.category_name,
						checked: false,
					};
				}

				// Add area to category
				categories[area.category_alias].list.push(area);
			});

			// Determine if all items in a category are selected
			for (const key in categories) {
				if (categories.hasOwnProperty(key)) {
					const category = categories[key];

					let unselectedAreas = category.list.filter(area => !area.checked);

					categories[key].checked = unselectedAreas.length === 0;
				}
			}

			return categories;
		},

		/**
		 * The range of allowable property classes
		 */
		classes() {
			let classes = [];

			if (this.residential && this.commercial) {
				classes.push({ label: 'Any', value: '' });
			}

			if (this.residential) {
				classes.push({ label: 'Residential', value: 'residential' });
			}

			if (this.commercial) {
				classes.push({ label: 'Commercial', value: 'commercial' });
			}

			return classes;
		},

		/**
		 * The range of allowable property contracts
		 */
		contracts() {
			let contracts = [];

			if (this.buying) {
				contracts.push({ label: 'For sale', value: 'buying' });
			}

			if (this.renting) {
				contracts.push({ label: 'To let', value: 'renting' });
			}

			if (this.auction) {
				contracts.push({ label: 'At auction', value: 'auction' });
			}

			return contracts;
		},

		/**
		 * The current expand icon, depending on current visibility
		 */
		icon() {
			return this.open ? this.openIcon : this.closedIcon;
		},

		/**
		 * Whether the currently selected search option is for commercial properties
		 */
		isCommercial() {
			return this.propertyClass === 'commercial';
		},

		/**
		 * Whether the currently selected search option is for rental properties
		 */
		isRenting() {
			return this.contract === 'renting';
		},

		/**
		 * The range of allowable prices for the current contract
		 */
		prices() {
			let contract = this.contract;

			if (contract == 'auction') {
				contract = 'buying';
			}

			return this.priceOptions[contract];
		},

		/**
		 * The appropriate refine label based on currently visibility
		 */
		refineLabel() {
			return this.open ? this.openLabel : this.closedLabel;
		},

		/**
		 * The currently selected search terms
		 */
		searchTerms() {
			return {
				contract: this.contract,
				ddlArea: this.selectedAreaValues,
				ddlBedrooms: this.ddlBedrooms,
				ddlPayMax: this.ddlPayMax,
				ddlPayMin: this.ddlPayMin,
				ddlRadius: this.ddlRadius,
				ddlResultsOrder: this.ddlResultsOrder,
				ddlUnderOffer: this.ddlUnderOffer ? 'on' : '',
				propertyClass: this.propertyClass,
				PropType: this.propType,
				txtQuickSearch: this.txtQuickSearch,
			};
		},

		/**
		 * Currently selected areas
		 */
		selectedAreas() {
			return this.areas.list.filter(area => area.checked);
		},

		/**
		 * Retrieve all currently selected areas
		 */
		selectedAreaLabels() {
			return this.selectedAreas.map(area => area.title);
		},

		/**
		 * Retrieve all currently selected areas
		 */
		selectedAreaValues() {
			return this.selectedAreas.map(area => area.definitions.value);
		},

		/**
		 * Determine whether areas are currently set up
		 */
		showAreas() {
			return this.includePropertyAreas && (!this.areas.loaded || this.areas.list.length);
		},

		/**
		 * The most appropriate under offer label for the selected contract
		 */
		underOfferLabel() {
			if (this.contract == 'buying') {
				return 'Include SSTC properties';
			} else {
				return 'Include properties Under Offer';
			}
		},
	},
	created() {
		// Apply URL parameters to values
		applyUrlVars(this.urlVars, this, { class: 'propertyClass' });

		// Update selected class value
		this.setSelectedClass();

		// Update prices lists
		this.loadPrices();

		// Update property types list
		if (this.includePropertyTypes) {
			this.loadPropertyTypes(this.propertyClass);
		}

		// Update property areas
		if (this.includePropertyAreas) {
			this.loadAreas();
		}

		// Check for settings stored locally
		var advancedSearch = localStorage.getItem('advanced-search');

		if (advancedSearch !== null) {
			this.advanced = advancedSearch == 'true';
		}
	},
	methods: {
		/**
		 * Load areas from the database
		 */
		loadAreas() {
			const url = getApiUrl('property-search-areas', this.apiNamespace);

			if (!isEmpty(url)) {
				this.areas.loading = true;
				this.areas.loaded = false;

				axios
					.get(url, {
						params: {
							categorise: this.categoriseAreas,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.areas.list = this.prepareAreas(data.areas);
						}

						this.areas.loading = false;
						this.areas.loaded = true;
					})
					.catch(error => {
						this.areas.loading = false;
						this.areas.loaded = true;

						console.error(url, error);
					});
			}
		},

		/**
		 * Get user-defined price brackets
		 */
		loadPrices() {
			const url = getApiUrl('property-prices', this.apiNamespace);

			if (!isEmpty(url)) {
				this.priceOptions.loading = true;
				this.priceOptions.loaded = false;

				axios
					.get(url)
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.priceOptions.buying = data.buying;
							this.priceOptions.renting = data.renting;
						}

						this.priceOptions.loading = false;
						this.priceOptions.loaded = true;
					})
					.catch(error => {
						this.priceOptions.loading = false;
						this.priceOptions.loaded = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.componentKey.errors.fail;
			}
		},

		/**
		 * Get available property types for this agent of the selected property class
		 * @param  {string}  propertyClass  The selected property class
		 */
		loadPropertyTypes(propertyClass) {
			if (this.propertyTypes.cache[propertyClass] !== undefined) {
				this.propertyTypes.list = this.propertyTypes.cache[propertyClass];
			} else {
				const url = getApiUrl('property-types', this.apiNamespace);

				if (!isEmpty(url)) {
					this.propertyTypes.loading = true;
					this.propertyTypes.loaded = false;

					axios
						.get(url, {
							params: {
								class: propertyClass,
							},
						})
						.then(({ data }) => {
							if (data && data.status === 'success') {
								this.propertyTypes.list = data.types;
								this.propertyTypes.cache[propertyClass] = data.types;
							}

							this.propertyTypes.loading = false;
							this.propertyTypes.loaded = true;
						})
						.catch(error => {
							this.propertyTypes.loading = false;
							this.propertyTypes.loaded = false;

							console.error(url, error);
						});
				} else {
					this.errorMessage = Language.componentKey.errors.fail;
				}
			}
		},

		/**
		 * Prepare areas for display
		 * @param {array}  areas  The areas to prepare
		 */
		prepareAreas(areas) {
			const hasAreas = this.urlVars.ddlArea !== undefined;

			areas.forEach((area, index) => {
				areas[index].checked = hasAreas && this.urlVars.ddlArea.includes(area.definitions.value);
			});

			return areas;
		},

		/**
		 * Set the currently selected class if only one option is found
		 */
		setSelectedClass() {
			if (this.classes.length == 1 && this.classes[0].value !== undefined) {
				this.propertyClass = this.classes[0].value;
			}
		},

		/**
		 * Toggle advanced options
		 */
		toggleAdvanced() {
			this.advanced = !this.advanced;
		},

		/**
		 * Toggle selection of all areas
		 */
		toggleAllAreas(allAreasSelected) {
			this.areas.list.forEach((area, index) => {
				this.areas.list[index].checked = !allAreasSelected;
				/* this.areas.list[index].disabled = !allAreasSelected; */
			});
		},

		/**
		 * Toggle area list visibility
		 */
		toggleAreaDisplay() {
			this.areas.open = !this.areas.open;
		},

		/**
		 * Toggle selection of all areas in a given category
		 * @param  {string}  key               The index of this category
		 * @param  {bool}    categorySelected  Whether this category is currently all selected
		 */
		toggleCategoryAreas(key, categorySelected) {
			if (this.categorisedAreas[key] !== undefined) {
				this.categorisedAreas[key].list.forEach((area, index) => {
					this.categorisedAreas[key].list[index].checked = !categorySelected;
				});
			}

			// Update areas disabled property
			/* this.areas.list.forEach((area, index) => {
				if (area.category_alias == key) {
					this.areas.list[index].disabled = !categorySelected;
				}
			}); */
		},

		/**
		 * Toggle refine search
		 */
		toggleSearch() {
			this.open = !this.open;
		},
	},
	watch: {
		// Watch for advanced option changing and store value
		advanced(value) {
			localStorage.setItem('advanced-search', value);
		},

		// Update selected property class if only one class is found
		classes() {
			this.setSelectedClass();
		},

		// Update property types when changing class
		propertyClass(propertyClass) {
			this.loadPropertyTypes(propertyClass);

			if (this.isCommercial) {
				this.ddlBedrooms = 0;
			}
		},
	},
};
