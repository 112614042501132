import { mapGetters, mapActions } from 'vuex';
import { popup } from '~/resources/js/libs/mixins';
import { isEmpty } from '~/resources/js/libs/helpers';

export default {
	name: 'so-property',
	mixins: [popup],
	delimiters: ['[[', ']]'],
	props: {
		detail: {
			type: Boolean,
			default: false,
		},
		localAreaTabs: {
			type: Array,
			default() {
				return [];
			},
		},
		postcode: {
			type: String,
			default: '',
		},
		view: {
			type: String,
			default: 'grid',
		},
	},
	data() {
		return {
			loadedLocalAreaData: false,
		};
	},
	computed: {
		...mapGetters('misc', {
			activeTab: 'activeTab',
		}),

		/**
		 * Determine whether we're in grid view
		 */
		gridView() {
			return this.view == 'grid';
		},

		/**
		 * Determine whether we're in list view
		 */
		listView() {
			return this.view == 'list';
		},
	},
	methods: {
		...mapActions('localArea', {
			loadAllLocalAreaData: 'loadAllLocalAreaData',
		}),
	},
	watch: {
		activeTab(activeTab) {
			// Load local area data for child components
			if (
				this.detail &&
				!isEmpty(this.postcode) &&
				!this.loadedLocalAreaData &&
				this.localAreaTabs.includes(activeTab)
			) {
				this.loadAllLocalAreaData(this.postcode);

				this.loadedLocalAreaData = true;
			}
		},
	},
};
