import { isEmpty } from '~/resources/js/libs/helpers';

export default {
	name: 'so-mortgage-calculator',
	template: '#so-mortgage-calculator-template',
	delimiters: ['[[', ']]'],
	props: {
		defaultDepositPercentage: {
			type: Number,
			default: 15,
		},
		initialInterestRate: {
			type: Number,
			default: 1.99,
		},
		initialPeriod: {
			type: Number,
			default: 25,
		},
		initialPropertyValue: {
			type: Number,
			required: true,
		},
	},
	data() {
		return {
			depositAmount: 0,
			interestRate: 0,
			period: 0,
			propertyValue: 0,
		};
	},
	created() {
		this.interestRate = this.initialInterestRate;
		this.period = this.initialPeriod;
		this.propertyValue = this.initialPropertyValue;

		// Set initial deposit value
		this.depositAmount = Math.ceil(this.propertyValue * (this.defaultDepositPercentage / 100));
	},
	computed: {
		/**
		 * Yearly interest rate converted into an amount applied per month
		 */
		interestRatePerMonth() {
			return this.interestRate / 1200;
		},

		/**
		 * The loan to value of this mortgage, given the current deposit amount
		 */
		loanToValue() {
			let ltv = Math.floor((this.mortgageAmount / this.propertyValue) * 100);

			return Math.max(ltv, 0);
		},

		/**
		 * The monthly cost to repay the mortgage over the given period
		 */
		monthlyRepayment() {
			let repayment = 0;

			if (this.interestRatePerMonth == 0) {
				repayment = this.mortgageAmount / this.period / 12;
			} else {
				repayment =
					(this.mortgageAmount * this.interestRatePerMonth) /
					(1 - 1 / Math.pow(1 + this.interestRatePerMonth, this.period * 12));
			}

			return Math.max(repayment, 0);
		},

		/**
		 * The remaining mortgage balance after accounting for deposit
		 */
		mortgageAmount() {
			let amount = this.propertyValue - this.depositAmount;

			return Math.max(amount, 0);
		},

		/**
		 * The total amount that will be repaid on this mortgage
		 */
		totalAmountToRepay() {
			return this.monthlyRepayment * this.period * 12;
		},

		/**
		 * Ensure input fields are valid
		 * @return  {boolean}  Whether all fields are valid
		 */
		validInput() {
			return (
				!isEmpty(this.depositAmount) &&
				!isEmpty(this.interestRate) &&
				!isEmpty(this.period) &&
				!isEmpty(this.propertyValue)
			);
		},
	},
};
