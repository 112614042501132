import moment from 'moment';
import Pikaday from 'pikaday';

export default {
	bind(element) {
		let pikadayOptions = {
			field: element,
			firstDay: 1,
			format: 'DD/MM/YYYY',
			onSelect: () => {
				let event = new Event('input', { bubbles: true });

				element.value = element.pikadayInstance.toString();
				element.dispatchEvent(event);
			},
		};

		if (element.dataset.maxDate !== undefined) {
			pikadayOptions.maxDate = moment(element.dataset.maxDate).toDate();
		}

		element.pikadayInstance = new Pikaday(pikadayOptions);
	},

	unbind: element => element.pikadayInstance.destroy(),
};
