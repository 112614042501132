import Vue from 'vue';
import Vuex from 'vuex';
import cookie from '~/resources/js/state/cookie';
import maps from '~/resources/js/state/maps';
import misc from '~/resources/js/state/misc';
import localArea from '~/resources/js/state/local-area';
import purchaser from '~/resources/js/state/purchaser';
import tenant from '~/resources/js/state/tenant';

Vue.use(Vuex);

let store = new Vuex.Store({
	modules: {
		cookie,
		localArea,
		maps,
		misc,
		purchaser,
		tenant,
	},
});

export default store;
