import { booleanToInt } from '~/resources/js/libs/number-helpers';
import { isEmpty } from '~/resources/js/libs/helpers';
import { map } from '~/resources/js/libs/mixins';
import { mapState, mapActions } from 'vuex';

export default {
	name: 'so-local-area-map',
	delimiters: ['[[', ']]'],
	mixins: [map],
	props: {
		iconPath: {
			type: String,
			required: true,
		},
		postcode: {
			type: String,
			required: true,
		},
		provider: {
			type: String,
			default: 'leaflet',
		},
		rootUrl: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			apiNamespace: 'local-area',
			iconTemplate: null,
			initialised: false,
			infoWindow: null,
			map: null,
			markers: [],
			sectors: {
				healthcare: {
					types: {
						optician: {
							label: 'Opticians',
							checked: true,
							type: 'OP',
							radius: 10,
							icon: 'spectacles',
						},
						dentist: { label: 'Dentists', checked: true, type: 'DT', radius: 10, icon: 'tooth' },
						hospital: {
							label: 'Hospitals',
							checked: true,
							type: 'HO',
							radius: 25,
							icon: 'ambulance',
						},
						gp: { label: 'GPs', checked: true, type: 'GP', radius: 10, icon: 'stethoscope' },
					},
					checked: true,
				},
				schools: {
					types: {
						nursery: { label: 'Nurseries', checked: true, icon: 'a-b-c' },
						primary: { label: 'Primary Schools', checked: true, icon: 'apple' },
						secondary: { label: 'Secondary Schools', checked: true, icon: 'backpack' },
						college: { label: 'Colleges', checked: true, icon: 'book' },
						higher: { label: 'Higher Education', checked: true, icon: 'square-academic-cap' },
					},
					checked: true,
				},
			},
		};
	},
	computed: {
		...mapState('localArea', {
			outlets: 'outlets',
		}),
	},
	methods: {
		...mapActions('maps', {
			initialiseMaps: 'initialiseMaps',
		}),

		/**
		 * Add event listener to open and close the given marker
		 * @param   {object}  marker  The marker to add the event listener to
		 */
		addListenerForGoogle(marker) {
			const $vm = this;

			google.maps.event.addListener(marker, 'click', function() {
				let event = this;

				$vm.infoWindow.close();
				$vm.infoWindow.setContent(marker.content);
				$vm.infoWindow.open($vm.map, event);
			});
		},

		/**
		 * Create a popup template for the given item
		 * @param {object} item  The item for which to create a template
		 */
		createDetailsTemplate(item) {
			let details = '';

			if (!isEmpty(item.name)) {
				details += `<strong>${item.name}</strong><br />`;
			}

			if (!isEmpty(item.address)) {
				details += `${item.address.replace(/, /g, ',<br />')}<br />`;
			}

			if (!isEmpty(item.telephone)) {
				details += item.telephone;
			}

			if (!isEmpty(item.ofsted_rating)) {
				details += '<br />Ofsted rating: ';

				if (!isEmpty(item.ofsted_report)) {
					details += `<a href="${item.ofsted_report}" target="_blank">`;
				}

				details += `<strong>${item.ofsted_rating}</strong>`;

				if (!isEmpty(item.ofsted_report)) {
					details += '</a>';
				}
			}

			return details;
		},

		/**
		 * Create a Google Maps marker for the current item
		 * @param  {object}  item  The item to create a marker for
		 */
		createMarkerForGoogle(item) {
			let latlng = new google.maps.LatLng(item.latitude, item.longitude);
			let icon = { ...this.iconTemplate };
			let details = this.createDetailsTemplate(item);

			icon.url = this.getIconPath(item.icon);

			let marker = new google.maps.Marker({
				animation: google.maps.Animation.DROP,
				position: latlng,
				map: this.map,
				icon: icon,
				sectorType: item.key,
				content: details,
			});

			this.markers.push(marker);

			// Add event listener to marker
			this.addListenerForGoogle(marker);
		},

		/**
		 * Create a Leaflet marker for the current item
		 * @param  {object}  item  The item to create a marker for
		 */
		createMarkerForLeaflet(item) {
			let icon = { ...this.iconTemplate };
			let details = this.createDetailsTemplate(item);

			icon.iconUrl = this.getIconPath(item.icon);

			let marker = L.marker([item.latitude, item.longitude], { icon: L.icon(icon) })
				.bindPopup(details)
				.addTo(this.map);

			// Track what type of marker this is
			marker.sectorType = item.key;

			this.markers.push(marker);
		},

		/**
		 * Create a Leaflet marker for the current item
		 * @param  {object}  item  The item to create a marker for
		 */
		createMarkerForMapbox(item) {
			let details = this.createDetailsTemplate(item);
			let markerElement = document.createElement('img');

			markerElement.src = this.getIconPath(item.icon);

			let marker = new mapboxgl.Marker(markerElement)
				.setLngLat([item.longitude, item.latitude])
				.setPopup(new mapboxgl.Popup().setHTML(details))
				.addTo(this.map);

			// Track what type of marker this is
			marker.sectorType = item.key;

			this.markers.push(marker);
		},

		/**
		 * Create Google Maps markers for the current outlets
		 */
		createMarkersForGoogle() {
			this.iconTemplate = {
				anchor: new google.maps.Point(13.5, 34),
				size: new google.maps.Size(27, 34),
				scaledSize: new google.maps.Size(27, 34),
				scale: 1,
			};

			this.infoWindow = new google.maps.InfoWindow();

			let sectors = Object.keys(this.outlets);

			sectors.forEach(sectorName => {
				// Don't show transit for now
				if (sectorName == 'transit') {
					return;
				}

				let sector = this.outlets[sectorName];

				if (sector.items) {
					sector.items.forEach(outlet => {
						this.createMarkerForGoogle(outlet);
					});
				} else {
					let types = Object.keys(sector);

					types.forEach(typeName => {
						sector[typeName].items.forEach(outlet => {
							this.createMarkerForGoogle(outlet);
						});
					});
				}
			});
		},

		/**
		 * Create Leaflet markers for the current outlets
		 */
		createMarkersForLeaflet() {
			this.iconTemplate = {
				iconSize: [27, 34],
				iconAnchor: [13.5, 34],
				popupAnchor: [0, -36],
			};

			let sectors = Object.keys(this.outlets);

			sectors.forEach(sectorName => {
				// Don't show transit for now
				if (sectorName == 'transit') {
					return;
				}

				let sector = this.outlets[sectorName];

				if (sector.items) {
					sector.items.forEach(outlet => {
						this.createMarkerForLeaflet(outlet);
					});
				} else {
					let types = Object.keys(sector);

					types.forEach(typeName => {
						sector[typeName].items.forEach(outlet => {
							this.createMarkerForLeaflet(outlet);
						});
					});
				}
			});
		},

		/**
		 * Create Mapbox markers for the current outlets
		 */
		createMarkersForMapbox() {
			let sectors = Object.keys(this.outlets);

			sectors.forEach(sectorName => {
				// Don't show transit for now
				if (sectorName == 'transit') {
					return;
				}

				let sector = this.outlets[sectorName];

				if (sector.items) {
					sector.items.forEach(outlet => {
						this.createMarkerForMapbox(outlet);
					});
				} else {
					let types = Object.keys(sector);

					types.forEach(typeName => {
						sector[typeName].items.forEach(outlet => {
							this.createMarkerForMapbox(outlet);
						});
					});
				}
			});
		},

		/**
		 * Initialise map with local area information
		 */
		mapsInitialiseCallback() {
			this.initialised = true;

			// Get a reference to the child map
			this.map = this.$refs.map.map;

			if (this.provider == 'google') {
				this.createMarkersForGoogle();
			} else if (this.provider == 'mapbox') {
				this.createMarkersForMapbox();
			} else {
				this.createMarkersForLeaflet();
			}
		},

		/**
		 * Set the visibility for a Google Maps marker
		 * @param  {object}  index    The marker index to update
		 * @param  {float}   visible  Whether the marker is visible
		 */
		setMarkerVisibilityForGoogle(index, visible) {
			this.markers[index].setVisible(visible);
		},

		/**
		 * Set the visibility for a Leaflet marker
		 * @param  {object}  index    The marker index to update
		 * @param  {float}   visible  Whether the marker is visible
		 */
		setMarkerVisibilityForLeaflet(index, visible) {
			visible = booleanToInt(visible);

			this.markers[index].setOpacity(visible);
		},

		/**
		 * Set the visibility for a Mapbox marker
		 * @param  {object}  index    The marker index to update
		 * @param  {float}   visible  Whether the marker is visible
		 */
		setMarkerVisibilityForMapbox(index, visible) {
			if (visible) {
				this.markers[index].addTo(this.map);
			} else {
				this.markers[index].remove();
			}
		},

		/**
		 * Toggle the icons for the given sector
		 * @param  {string}  sectorName  The name of the sector to toggle
		 * @param  {string}  sector      The sector to toggle
		 */
		toggleSector(sectorName, sector) {
			let checked = !sector.checked;
			let types = this.sectors[sectorName].types;

			for (let i = 0; i < this.markers.length; i++) {
				let marker = this.markers[i];

				window.testIndex = i;

				if (Object.keys(types).includes(marker.sectorType)) {
					// Only re-check if the item was previously checked
					if ((checked && types[marker.sectorType].checked) || !checked) {
						if (this.provider == 'google') {
							this.setMarkerVisibilityForGoogle(i, checked);
						} else if (this.provider == 'mapbox') {
							this.setMarkerVisibilityForMapbox(i, checked);
						} else {
							this.setMarkerVisibilityForLeaflet(i, checked);
						}
					}
				}
			}
		},

		/**
		 * Toggle the icons for the given type
		 * @param  {string}  typeName  The name of the type to toggle
		 * @param  {string}  type      The type to toggle
		 */
		toggleType(typeName, type) {
			let checked = !type.checked;

			for (let i = 0; i < this.markers.length; i++) {
				if (this.markers[i].sectorType == typeName) {
					if (this.provider == 'google') {
						this.setMarkerVisibilityForGoogle(i, checked);
					} else if (this.provider == 'mapbox') {
						this.setMarkerVisibilityForMapbox(i, checked);
					} else {
						this.setMarkerVisibilityForLeaflet(i, checked);
					}
				}
			}
		},
	},
	watch: {
		outlets: {
			deep: true,
			handler() {
				if (this.initialised) {
					// Create new icons when outlets change
					if (this.provider == 'google') {
						this.deleteMarkersForGoogle();
						this.createMarkersForGoogle();
					} else if (this.provider == 'mapbox') {
						this.deleteMarkersForMapbox();
						this.createMarkersForMapbox();
					} else {
						this.deleteMarkersForLeaflet();
						this.createMarkersForLeaflet();
					}
				}
			},
		},
	},
};
