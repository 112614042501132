import { isEmpty } from '~/resources/js/libs/helpers';

export default {
	name: 'so-checkbox',
	template: '#so-checkbox-template',
	delimiters: ['[[', ']]'],
	props: {
		checkedValue: {
			type: String,
			default: '',
		},
		fieldId: {
			type: String,
			required: true,
		},
		fieldName: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		},
		uncheckedValue: {
			type: String,
			default: 'Unchecked',
		},
	},
	data() {
		return {
			checked: false,
		};
	},
	computed: {
		/**
		 * The current value of the checkbox
		 */
		value() {
			if (this.checked) {
				return !isEmpty(this.checkedValue) ? this.checkedValue : this.label;
			} else {
				return this.uncheckedValue;
			}
		},
	},
};
