import { chunk } from 'lodash';
import { mapState } from 'vuex';

export default {
	name: 'so-local-area-information',
	template: '#so-local-area-information-template',
	delimiters: ['[[', ']]'],
	props: {},
	data() {
		return {
			errorMessage: '',
		};
	},
	computed: {
		...mapState('localArea', {
			outlets: 'outlets',
		}),

		healthcare() {
			if (!this.outlets) {
				return [];
			}

			return this.outlets.healthcare;
		},

		schools() {
			if (!this.outlets) {
				return [];
			}

			return this.outlets.schools;
		},

		schoolsChunks() {
			return chunk(this.schools.items, this.schools.items.length / 2);
		},

		transit() {
			if (!this.outlets) {
				return [];
			}

			return this.outlets.transit;
		},
	},
	methods: {
		/**
		 * Determine if the given type of outlet has any members
		 */
		hasOutlets(category, type) {
			if (!this.outlets) {
				return false;
			}

			let item = this.outlets[category];

			if (type !== undefined) {
				item = item[type];
			}

			return item.loaded && item.items.length;
		},
	},
};
