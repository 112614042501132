import { mapState } from 'vuex';
import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';
import Language from '~/resources/js/libs/language';

export default {
	name: 'so-market-stats',
	template: '#so-market-stats-template',
	delimiters: ['[[', ']]'],
	props: {
		contract: {
			type: String,
			default: 'renting',
		},

		parentTab: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			apiNamespace: 'local-area',
			bedroomCount: 0,
			bedroomStats: [],
			errorMessage: '',
			loadedMarketStats: false,
			loadingMarketStats: false,
			stats: [],
		};
	},
	computed: {
		...mapState('misc', {
			activeTab: 'activeTab',
		}),

		/**
		 * Determine whether there are any market stats to display
		 */
		hasMarketStats() {
			return this.stats.length;
		},

		/**
		 * Determine whether the current data is for rentals
		 */
		isRenting() {
			return this.contract == 'renting';
		},
	},
	methods: {
		/**
		 * Load current market stats data
		 */
		loadMarketStats() {
			const url = getApiUrl('market-stats', this.apiNamespace);

			// Reset error message
			this.errorMessage = '';

			if (!isEmpty(url)) {
				this.loadingMarketStats = true;
				this.loadedMarketStats = false;

				axios
					.get(url, {
						params: {
							contract: this.contract,
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.stats = data.stats;
							this.bedroomStats = data.bedroom_stats;
							this.bedroomCount = data.bedroom_count;
						} else {
							this.errorMessage = fallback(
								data.message,
								Language.localArea.marketStats.errors.load
							);
						}

						this.loadingMarketStats = false;
						this.loadedMarketStats = true;
					})
					.catch(error => {
						if (error.response) {
							let data = error.response.data;

							this.errorMessage = fallback(
								data.message,
								Language.localArea.marketStats.errors.load
							);
						} else {
							this.errorMessage = Language.localArea.marketStats.errors.load;
						}

						this.loadingMarketStats = false;

						console.error(url, error);
					});
			} else {
				this.errorMessage = Language.localArea.marketStats.errors.load;
			}
		},
	},
	watch: {
		activeTab(activeTab) {
			if (activeTab === this.parentTab && !this.loadedMarketStats && !this.loadingMarketStats) {
				this.loadMarketStats();
			}
		},
	},
};
