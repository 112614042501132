import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-instant-valuation',
	template: '#so-instant-valuation-template',
	delimiters: ['[[', ']]'],
	data() {
		return {
			apiNamespace: 'valuation',
			errorMessage: '',
			form: {
				valuationAddress: '',
				valuationPostcode: '',
				valuationPropertyType: '',
			},
			valuation: null,
		};
	},
	computed: {
		allowLookup() {
			return (
				!isEmpty(this.form.valuationAddress) &&
				!isEmpty(this.form.valuationPostcode) &&
				!isEmpty(this.form.valuationPropertyType)
			);
		},

		showValuation() {
			return this.valuation !== null;
		},
	},
	methods: {
		/**
		 * Reset component to original state
		 */
		reset() {
			this.errorMessage = '';
			this.valuation = null;
			this.form.valuationAddress = '';
			this.form.valuationPostcode = '';
			this.form.valuationPropertyType = '';
		},

		/**
		 * Fetch possible addresses for given postcode
		 */
		valuationLookup() {
			const url = getApiUrl('instant-valuation', this.apiNamespace);

			if (!isEmpty(url)) {
				// Just in case this was triggered in some other way
				this.$refs['valuation-lookup-button'].processing = true;

				// Reset error message
				this.errorMessage = '';

				axios
					.get(url, {
						params: {
							address: this.form.valuationAddress,
							postcode: this.form.valuationPostcode,
							property_type: this.form.valuationPropertyType,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							this.valuation = data.valuation;
						} else {
							this.errorMessage = fallback(data.error, Language.valuation.errors.fail);
						}

						this.$refs['valuation-lookup-button'].processing = false;
					})
					.catch(error => {
						this.errorMessage = Language.valuation.errors.fail;

						this.$refs['valuation-lookup-button'].processing = false;

						console.error(url, error);
					});
			}
		},
	},
};
