import { mapState } from 'vuex';
import { responsive } from '~/resources/js/libs/mixins';

export default {
	name: 'so-navigation',
	mixins: [responsive],
	delimiters: ['[[', ']]'],
	data() {
		return {
			closedIcon: 'hamburger-icon',
			open: false,
			openIcon: 'cross',
		};
	},
	computed: {
		...mapState('misc', {
			svgPath: 'svgPath',
		}),

		/**
		 * The currently appropriate icon based on visibility
		 */
		icon() {
			var icon = this.open ? this.openIcon : this.closedIcon;

			return [this.svgPath, icon].join('#');
		},
	},
	methods: {
		toggleNav() {
			this.open = !this.open;
		},
	},
};
