import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
	name: 'so-ajax-form',
	template: '#so-ajax-form-template',
	delimiters: ['[[', ']]'],
	props: {
		additionalData: {
			type: String,
			default: '',
		},
		callback: {
			type: String,
			default: 'onFormResponse',
		},
		callbackUrl: {
			type: String,
			default: '',
		},
		excludeTemplate: {
			type: Boolean,
			default: true,
		},
		formId: {
			type: String,
			required: true,
		},
		scroll: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			destination: '',
			error: false,
			formElement: null,
			formSubmit: null,
		};
	},
	mounted() {
		// Capture form elements
		this.formElement = this.$el.querySelector('.form');
		this.formSubmit = this.$el.querySelector('button[type="submit"]');

		// Set form destination
		this.setDestination();

		// Monitor submit event
		this.formElement.addEventListener('submit', this.postForm);
	},
	methods: {
		/**
		 * Fetch current data for all form elements
		 */
		getFormData() {
			let params = new URLSearchParams();

			for (let i = 0; i < this.formElement.elements.length; i++) {
				let element = this.formElement.elements[i];

				params.set(element.name, element.value);
			}

			return params;
		},

		/**
		 * Post form to given destination
		 * @param  {object}  event  The submit event
		 */
		postForm(event) {
			event.preventDefault();

			// Fetch new form data
			let params = this.getFormData();

			if (isEmpty(params.get('__hpname'))) {
				const url = this.destination;

				// Show feedback
				this.formSubmit.disabled = true;
				this.formSubmit.textContent = 'Sending…';

				// Store message in database
				axios
					.post(url, params)
					.then(({ data }) => {
						// Find form contents
						let parser = new DOMParser();
						let doc = parser.parseFromString(data, 'text/html');

						// Determine what type of response we have
						let parent = doc.querySelector(`[form-id="${this.formId}"]`);
						let responseObject = parent.querySelector('.response');

						// Try to find a form
						if (responseObject == null) {
							responseObject = parent.querySelector('.form');
						}

						// If not found, try to find an alert
						if (responseObject == null) {
							responseObject = parent.querySelector('.alert');
						}

						let content = responseObject.innerHTML;

						// Fill in HTML
						this.formElement.classList.add('form-success');
						this.formElement.innerHTML = content;

						// Refresh captcha image
						let captchaBox = this.formElement.querySelector('[data-cgbf-captcha]');

						if (captchaBox != null) {
							let captchaImage = captchaBox.querySelector('img');

							if (captchaImage != null) {
								captchaImage.src = `${captchaImage.src}&refresh`;
							}
						}

						// Scroll back to top of parent
						if (this.scroll) {
							this.formElement.scrollIntoView({ behavior: 'smooth' });
						}

						// Call parent function to run when this button is activated
						const callback = this.$parent[this.callback];

						if (typeof callback === 'function') {
							callback(params);
						}

						if (!isEmpty(this.callbackUrl)) {
							const url = getApiUrl(this.callbackUrl);

							// Send form data for additional processing
							if (!isEmpty(url)) {
								axios.post(url, params);
							}
						}
					})
					.catch(error => {
						console.error(url, error);
					});
			}
		},

		/**
		 * Determine form submission destination
		 */
		setDestination() {
			let url = [];

			url.push(this.formElement.action);

			if (this.excludeTemplate) {
				url.push('?showtemplate=false');
			}

			if (!isEmpty(this.additionalData)) {
				url.push(this.additionalData);
			}

			this.destination = url.join('');
		},
	},
};
