import { getUrlVars } from '~/resources/js/libs/helpers';

export default {
	namespaced: true,
	state: {
		activeTab: '',
		reactive: false,
		svgPath: 'build/img/app.svg',
		urlVars: null,
	},
	getters: {
		activeTab: state => {
			if (typeof state.activeTab == 'object') {
				if (state.activeTab.name !== undefined) {
					return state.activeTab.name;
				}

				return '';
			}

			return state.activeTab;
		},
		activeTabScroll: state => {
			if (typeof state.activeTab == 'object') {
				if (state.activeTab.scroll !== undefined) {
					return state.activeTab.scroll;
				}
			}

			return false;
		},
	},
	actions: {
		/**
		 * Update the currently active tab
		 */
		updateActiveTab: ({ commit }, tab) => {
			commit('setActiveTab', tab);
		},

		/**
		 * Update the current reactive state
		 */
		updateReactive: ({ commit }, isReactive) => {
			commit('setReactive', isReactive);
		},

		/**
		 * Get the current URL parameters using helper function and commit to store
		 */
		updateUrlVars: ({ commit }) => {
			let urlVars = getUrlVars();

			commit('setUrlVars', urlVars);
		},
	},
	mutations: {
		/**
		 * Set the currently active tab
		 */
		setActiveTab: (state, tab) => {
			state.activeTab = tab;
		},

		/**
		 * Set the reactive state
		 */
		setReactive: (state, reactive) => {
			state.reactive = reactive;
		},

		/**
		 * Set url parameters
		 */
		setUrlVars: (state, urlVars) => {
			state.urlVars = urlVars;
		},
	},
};
