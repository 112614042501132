export default {
	namespaced: true,
	state: {
		mapScriptNeeded: true,
		mapsReady: false,
	},
	actions: {
		/**
		 * Declare Google Maps to be ready
		 */
		initialiseMaps: ({ commit }) => {
			commit('setMapsReady');
		},

		/**
		 * Update whether map scripts are still needed
		 */
		mapsNoLongerNeeded: ({ commit }) => {
			commit('setMapScriptNeeded');
		},
	},
	mutations: {
		/**
		 * Set whether map scripts are still needed
		 */
		setMapScriptNeeded: state => {
			state.mapScriptNeeded = false;
		},

		/**
		 * Set maps ready
		 */
		setMapsReady: state => {
			state.mapsReady = true;
		},
	},
};
