import { mapActions } from 'vuex';

export default {
	name: 'so-activate-tab',
	delimiters: ['[[', ']]'],
	props: {
		scroll: {
			type: Boolean,
			default: false,
		},
		tabId: {
			type: String,
			required: true,
		},
	},
	methods: {
		...mapActions('misc', {
			updateActiveTab: 'updateActiveTab',
		}),

		activateTab() {
			this.updateActiveTab({
				name: this.tabId,
				scroll: this.scroll,
			});
		},
	},
};
