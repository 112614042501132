/**
 * ------------------------------------------------------------
 * Update root class to show JS loaded
 * ------------------------------------------------------------
 */

document.documentElement.classList.remove('no-js');
document.documentElement.classList.add('js');

/**
 * ------------------------------------------------------------
 * lazyload - lazy load images
 * ------------------------------------------------------------
 */

import LazyLoad from 'vanilla-lazyload/dist/lazyload.min.js';

window.LazyLoadInstance = new LazyLoad({
	class_loading: 'lazy-loading',
	class_loaded: 'lazy-loaded',
	class_error: 'lazy-error',
	class_initial: 'lazy-initial',
	use_native: true,
});

/**
 * ------------------------------------------------------------
 * svgxuse - better cross-browser SVG fragment support
 * ------------------------------------------------------------
 */

window.svgxuse = require('svgxuse');

/**
 * ------------------------------------------------------------
 * 'closest' polyfill - IE11.0
 * ------------------------------------------------------------
 */

if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector;

if (!Element.prototype.closest)
	Element.prototype.closest = function(selector) {
		var el = this;

		while (el) {
			if (el.matches(selector)) {
				return el;
			}

			el = el.parentElement;
		}
	};
