import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-postcode-lookup',
	template: '#so-postcode-lookup-template',
	delimiters: ['[[', ']]'],
	props: {
		id: {
			type: String,
			default: 'postcode',
		},
		initialAddress: {
			default: '',
		},
		initialPostcode: {
			default: '',
		},
		name: {
			type: String,
			default: 'postcode',
		},
	},
	data() {
		return {
			address: '',
			apiNamespace: 'location',
			errorMessage: '',
			latitude: '',
			longitude: '',
			postcode: '',
			remainingAddress: '',
			results: [],
		};
	},
	computed: {
		/**
		 * Only allow lookup when a postcode meets minimum length
		 */
		allowLookup() {
			return !isEmpty(this.postcode) && this.postcode.length >= 5;
		},

		/**
		 * Whether to display lookup results
		 */
		displayResults() {
			return this.results.length && isEmpty(this.address);
		},
	},
	methods: {
		/**
		 * Fetch possible addresses for given postcode
		 */
		postcodeLookup() {
			const url = getApiUrl('postcode-lookup', this.apiNamespace);

			if (!isEmpty(url)) {
				// Standardise postcode
				this.postcode = this.postcode.toUpperCase();

				// Reset previous data
				this.errorMessage = '';
				this.address = '';
				this.latitude = '';
				this.longitude = '';
				this.remainingAddress = '';
				this.results = [];

				// Just in case this was triggered in some other way
				this.$refs['postcode-lookup-button'].processing = true;

				axios
					.get(url, {
						params: {
							postcode: this.postcode,
						},
					})
					.then(({ data }) => {
						if (data && data.status == 'success') {
							this.latitude = data.latitude;
							this.longitude = data.longitude;
							this.postcode = data.postcode;
							this.remainingAddress = data.remaining_address;
							this.results = data.results;
						} else {
							this.errorMessage = fallback(data.error, Language.postcode.errors.fail);
						}

						this.$refs['postcode-lookup-button'].processing = false;
					})
					.catch(error => {
						this.errorMessage = Language.postcode.errors.fail;

						this.$refs['postcode-lookup-button'].processing = false;

						console.error(url, error);
					});
			}
		},

		/**
		 * Set the current address
		 * @param  {string}  address  The address to set
		 */
		setSelectedAddress(address) {
			this.address = address;
		},

		/**
		 * Allow re-selecting an address
		 */
		unsetSelectedAddress() {
			this.address = '';
		},
	},
	watch: {
		// Pass selected address to parent
		address(value) {
			this.$emit('update:initialAddress', value);
		},

		// Pass postcode to parent
		postcode(value) {
			this.$emit('update:initialPostcode', value);
		},
	},
};
