export default {
	namespaced: true,
	state: {
		selectedProperty: null,
	},
	actions: {
		/**
		 * Load the available features for the current agent
		 */
		updateSelectedProperty: ({ commit }, property) => {
			commit('setSelectedProperty', Object.freeze(property));
		},
	},
	mutations: {
		/**
		 * Set the available features for match requirements
		 */
		setSelectedProperty: (state, property) => {
			state.selectedProperty = property;
		},
	},
};
