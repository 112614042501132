export default {
	name: 'so-stamp-duty',
	template: '#so-stamp-duty-template',
	delimiters: ['[[', ']]'],
	props: {
		commercial: {
			type: Boolean,
			default: false,
		},
		propertyValue: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			additionalPropertyUplift: 0.03,
			duty: 0,
			commercialBands: {
				150000: 0,
				250000: 0.02,
				Infinity: 0.05,
			},
			residentialBands: {
				125000: 0,
				250000: 0.02,
				925000: 0.05,
				1500000: 0.1,
				Infinity: 0.12,
			},
		};
	},
	computed: {
		/**
		 * The stamp duty required for additional properties
		 * Only applies to residential properties
		 */
		additionalPropertyDuty() {
			return this.getPropertyStampDuty(false);
		},

		/**
		 * The stamp duty required for a single property owner
		 */
		singlePropertyDuty() {
			return this.getPropertyStampDuty(true);
		},
	},
	methods: {
		/**
		 * Calculate the stamp duty required for the current property
		 * @param  {boolean}  single  Whether this is a single property ownership
		 */
		getPropertyStampDuty(single) {
			if (single === undefined) {
				single = true;
			}

			let bands = this.residentialBands;

			if (single && this.commercial) {
				bands = this.commercialBands;
			}

			let cumulativeDuty = 0;
			let previousLimit = 0;

			for (const limit in bands) {
				if (bands.hasOwnProperty(limit)) {
					let fee = bands[limit];

					if (!single) {
						fee += this.additionalPropertyUplift;
					}

					// Add this band to the cumulative duty
					if (this.propertyValue < limit) {
						cumulativeDuty += (this.propertyValue - previousLimit) * fee;

						break;
					} else {
						cumulativeDuty += (limit - previousLimit) * fee;

						// Store current band for next iteration
						previousLimit = limit;
					}
				}
			}

			return cumulativeDuty;
		},
	},
};
