import { mapState, mapActions } from 'vuex';
import { map } from '~/resources/js/libs/mixins';
import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
    name: 'so-property-results-map',
    template: '#so-property-results-map-template',
    mixins: [map],
    delimiters: ['[[', ']]'],
    props: {
        chainId: {
            type: String,
            required: true,
        },
        iconPath: {
            type: String,
            required: true,
        },
        provider: {
            type: String,
            default: 'leaflet',
        },
        view: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            apiNamespace: 'property/search',
            bounds: [],
            defaultPopupContent: 'Sorry, property details could not be loaded',
            drawing: false,
            drawingDone: false,
            drawingManager: null,
            error: false,
            iconTemplate: null,
            infoWindow: null,
            loaded: false,
            loading: false,
            map: null,
            mapboxDrawControl: null,
            mapElement: null,
            markers: [],
            originalBounds: null,
            plots: [],
            polygon: '',
        };
    },
    computed: {
        ...mapState('misc', {
            urlVars: 'urlVars',
        }),

        canDrawMap() {
            return this.provider === 'google';
        },
    },
    created() {
        // Load data immediately
        this.loadData();
    },
    mounted() {
        this.mapElement = this.$refs.map;
    },
    methods: {
        ...mapActions('maps', {
            initialiseMaps: 'initialiseMaps',
        }),

        /**
         * Add event listener to open and close the given marker
         * @param   {object}  marker  The marker to add the event listener to
         */
        addListenerForGoogle(marker) {
            const $vm = this;
            const url = this.url;

            google.maps.event.addListener(marker, 'click', function() {
                let event = this;

                $vm.infoWindow.close();

                let defaultContent = this.defaultPopupContent;

                // Load property details
                axios
                    .get(url, {
                        params: {
                            property_id: marker.PropertyID,
                        },
                    })
                    .then(({ data }) => {
                        $vm.infoWindow.setContent(data);
                        $vm.infoWindow.open($vm.map, event);
                    })
                    .catch(error => {
                        $vm.infoWindow.setContent(defaultContent);
                        $vm.infoWindow.open($vm.map, event);

                        this.error = true;

                        console.error(url, error);
                    });
            });
        },

        /**
         * Add event listener to open and close the given marker
         * @param  {object}  marker  The marker to add the event listener to
         */
        addListenerForLeaflet(marker) {
            const url = this.url;

            marker.on('click', e => {
                let popup = e.target.getPopup();

                if (popup === undefined) {
                    let defaultContent = this.defaultPopupContent;

                    // Load property details
                    axios
                        .get(url, {
                            params: {
                                property_id: marker.PropertyID,
                            },
                        })
                        .then(({ data }) => {
                            marker.bindPopup(data).openPopup();
                        })
                        .catch(error => {
                            marker.bindPopup(defaultContent).openPopup();

                            this.error = true;

                            console.error(url, error);
                        });
                }
            });
        },

        /**
         * Add event listener to open and close the given marker
         * @param  {object}  markerElement  The element representing a marker
         * @param  {object}  marker         The marker to add the event listener to
         */
        addListenerForMapbox(markerElement, marker) {
            const url = this.url;

            markerElement.addEventListener('click', e => {
                let popup = marker.getPopup();
                console.log('clicked', marker, popup);

                if (popup === null) {
                    let defaultContent = this.defaultPopupContent;

                    popup = new mapboxgl.Popup();

                    // Load property details
                    axios
                        .get(url, {
                            params: {
                                property_id: marker.PropertyID,
                            },
                        })
                        .then(({ data }) => {
                            popup.setHTML(data);

                            marker.setPopup(popup).togglePopup();
                        })
                        .catch(error => {
                            popup.setHTML(defaultContent);

                            marker.setPopup(popup).togglePopup();

                            this.error = true;

                            console.error(url, error);
                        });
                }
            });
        },

        /**
         * Remove polygon and reset markers
         */
        clearPolygon() {
            if (typeof this.polygon != 'string') {
                this.polygon.setMap(null);
                this.polygon = '';
            }

            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setVisible(true);
            }

            this.map.fitBounds(this.bounds);

            // Reset state
            this.drawing = false;
            this.drawingDone = false;
        },

        /**
         * Create a map using Google Maps
         */
        createMapForGoogle() {
            this.map = new google.maps.Map(this.mapElement);
            this.bounds = new google.maps.LatLngBounds();

            // Create info window
            this.infoWindow = new google.maps.InfoWindow();

            // Create drawing manager
            this.drawingManager = new google.maps.drawing.DrawingManager({
                drawingControl: false,
                polygonOptions: {
                    editable: true,
                    fillOpacity: 0.45,
                    fillColor: '#1e90ff',
                    strokeWeight: 0,
                },
            });

            this.drawingManager.setMap(this.map);

            // Set overlay listener
            this.setPolygonListener();

            // Polygon extension to determine whether a given Point (Lat,Lng) is within a polygon
            google.maps.Polygon.prototype.Contains = function(point) {
                let crossings = 0;
                let path = this.getPath();

                // Each edge
                for (let i = 0; i < path.getLength(); i++) {
                    let a = path.getAt(i);
                    let j = i + 1;

                    if (j >= path.getLength()) {
                        j = 0;
                    }

                    let b = path.getAt(j);

                    if (rayCrossesSegment(point, a, b)) {
                        crossings++;
                    }
                }

                // Odd number of crossings?
                return crossings % 2 == 1;

                function rayCrossesSegment(point, a, b) {
                    let px = point.lng();
                    let py = point.lat();
                    let ax = a.lng();
                    let ay = a.lat();
                    let bx = b.lng();
                    let by = b.lat();

                    if (ay > by) {
                        ax = b.lng();
                        ay = b.lat();
                        bx = a.lng();
                        by = a.lat();
                    }

                    // Alter longitude to cater for 180 degree crossings
                    if (px < 0) {
                        px += 360;
                    }

                    if (ax < 0) {
                        ax += 360;
                    }

                    if (bx < 0) {
                        bx += 360;
                    }

                    if (py == ay || py == by) {
                        py += 0.00000001;
                    }

                    if (py > by || py < ay || px > Math.max(ax, bx)) {
                        return false;
                    }

                    if (px < Math.min(ax, bx)) {
                        return true;
                    }

                    let red = ax != bx ? (by - ay) / (bx - ax) : Infinity;
                    let blue = ax != px ? (py - ay) / (px - ax) : Infinity;

                    return blue >= red;
                }
            };

            // Define bounds based on a Polygon's outline
            google.maps.Polygon.prototype.getBounds = function() {
                let bounds = new google.maps.LatLngBounds();
                let paths = this.getPaths();
                let path;

                for (let i = 0; i < paths.getLength(); i++) {
                    path = paths.getAt(i);

                    for (let ii = 0; ii < path.getLength(); ii++) {
                        bounds.extend(path.getAt(ii));
                    }
                }
                return bounds;
            };
        },

        /**
         * Create a map using Leaflet
         */
        createMapForLeaflet() {
            this.map = L.map(this.mapElement);

            // Tiles
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution:
                    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(this.map);
        },

        /**
         * Create a map using Mapbox
         */
        createMapForMapbox() {
            this.bounds = new mapboxgl.LngLatBounds();

            mapboxgl.accessToken = this.mapsApiKey;

            // Initialise Mapbox map
            this.map = new mapboxgl.Map({
                attributionControl: false,
                container: this.mapElement,
                style: 'mapbox://styles/mapbox/streets-v10',
            });

            // Add compact variant of attribution
            this.map.addControl(
                new mapboxgl.AttributionControl({
                    compact: true,
                })
            );

            // Add navigation
            let nav = new mapboxgl.NavigationControl();

            this.map.addControl(nav, 'top-left');
        },

        /**
         * Create a Google Maps marker for the current item
         * @param  {object}  item  The item to create a marker for
         */
        createMarkerForGoogle(item) {
            // Define a marker icon
            let icon = { ...this.iconTemplate };

            icon.url = this.getIconPath('pin');

            // Update bounds to contain this icon
            let latlng = new google.maps.LatLng(item.Latitude, item.Longitude);

            this.bounds.extend(latlng);

            // Create marker
            let marker = new google.maps.Marker({
                animation: google.maps.Animation.DROP,
                position: latlng,
                map: this.map,
                icon: icon,
				Latitude   : item.Latitude,
				Longitude  : item.Longitude,                
                PropertyID: item.PropertyID, // Store the current property for popup loading later
            });

            // Add event listener to marker
            this.addListenerForGoogle(marker);

            this.markers.push(marker);
        },

        /**
         * Create a Leaflet marker for the current item
         * @param  {object}  item  The item to create a marker for
         */
        createMarkerForLeaflet(item) {
            // Define a marker icon
            let icon = { ...this.iconTemplate };

            icon.iconUrl = this.getIconPath('pin');

            // Update bounds to contain this marker
            let latlng = [item.Latitude, item.Longitude];

            this.bounds.push(latlng);

            // Create marker
            let marker = L.marker(latlng, { icon: L.icon(icon) });

            // Store the current property for popup loading later
            marker.PropertyID = item.PropertyID;

            // Add popup event listener
            this.addListenerForLeaflet(marker);

            marker.addTo(this.map);

            this.markers.push(marker);
        },

        /**
         * Create a Leaflet marker for the current item
         * @param  {object}  item  The item to create a marker for
         */
        createMarkerForMapbox(item) {
            // Define a marker image
            let markerElement = document.createElement('img');

            markerElement.src = this.getIconPath('pin');
            markerElement.style.cursor = 'pointer';

            // Update bounds
            this.bounds.extend(new mapboxgl.LngLat(item.Longitude, item.Latitude));

            // Create marker
            let marker = new mapboxgl.Marker(markerElement).setLngLat([item.Longitude, item.Latitude]);

            // Store the current property for popup loading later
            marker.PropertyID = item.PropertyID;

            // Add popup event listener
            this.addListenerForMapbox(markerElement, marker);

            marker.addTo(this.map);

            this.markers.push(marker);
        },

        /**
         * Draw a polygon on the map to narrow search
         */
        drawMap() {
            // Set state
            if (this.drawing) {
                this.drawingDone = true;
            } else {
                this.drawing = true;
            }

            // Clear any existing markers to make drawing a precise polygon easier
            this.deleteMarkersForGoogle();

            // If a polygon exists, remove it
            if (typeof this.polygon != 'string') {
                this.polygon.setMap(null);
                this.polygon = '';
            } else {
                this.originalBounds = this.bounds;
            }

            // Enable Polygon drawing mode
            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        },
        /**
         * Remove markers and reset MarkerClusterer
         */
        deleteMarkersForGoogle: function() {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setVisible(false);
            }
        },

        /**
         * Determine search parameters from URL
         */
        getSearchParams() {
            let params = { ...this.urlVars };

            params.ChainID = this.chainId;

            return params;
        },

        /**
         * Initialise map
         */
        init() {
            if (this.mapsReady && this.loaded && !this.initialised) {
        
                // Notify other maps that script has loaded
                this.initialiseMaps('setMapsReady');
                
                this.initialised = true;

                if (this.provider == 'google') {
                    this.createMapForGoogle();
                } else if (this.provider == 'mapbox') {
                    this.createMapForMapbox();
                } else {
                    this.createMapForLeaflet();
                }

                // Set markers
                this.setMarkers();
            }
        },

        /**
         * Load map markers
         */
        loadData() {
            const url = getApiUrl('property-map', this.apiNamespace);

            // Reset error message
            this.error = false;

            if (!isEmpty(url)) {
                this.loading = true;

                axios
                    .post(url, this.getSearchParams())
                    .then(({ data }) => {
                        if (data && data.status === 'success') {
                            this.error = false;

                            this.plots = data.plots;

                            this.loading = false;
                            this.loaded = true;

                            // Call initialisation, as fetch may complete after maps initialisation
                            this.init();
                        } else {
                            this.error = true;
                            this.loading = false;
                        }
                    })
                    .catch(error => {
                        this.error = true;
                        this.loading = false;

                        console.error(url, error);
                    });
            } else {
                this.error = true;
                this.loading = false;
            }
        },

        /**
         * Set the current plots on the map
         */
        setMarkers() {
            this.plots.forEach(plot => {
                if (this.provider == 'google') {
                    this.createMarkerForGoogle(plot);
                } else if (this.provider == 'mapbox') {
                    this.createMarkerForMapbox(plot);
                } else {
                    this.createMarkerForLeaflet(plot);
                }
            });

            this.map.fitBounds(this.bounds);
        },

        /**
         * Map properties based on search results and given polygon
         */
        showMarkersInPolygon() {


            this.markers.forEach(marker => {
                let latlng = new google.maps.LatLng(marker.Latitude, marker.Longitude);

                if (!this.polygon || !this.polygon.Contains(latlng)) {
                    return;
                }

                marker.setVisible(true);
            });
        },

        /**
         * Initialise listeners for polygon drawing
         */
        setPolygonListener() {
            // Triggered when a polygon is finished
            google.maps.event.addListener(this.drawingManager, 'overlaycomplete', object => {

                this.drawingManager.setDrawingMode(null);

                this.drawing = false;
                this.drawingDone = true;

                this.polygon = object.overlay;

                this.showMarkersInPolygon();
                this.map.fitBounds(this.polygon.getBounds());

                // When a mid-point between two corners is moved
                google.maps.event.addListener(this.polygon.getPath(), 'insert_at', () => {
                    this.deleteMarkersForGoogle();
                    this.showMarkersInPolygon();
                    this.map.fitBounds(this.polygon.getBounds());
                });

                // When a corner is moved
                google.maps.event.addListener(this.polygon.getPath(), 'set_at', () => {
                    this.deleteMarkersForGoogle();
                    this.showMarkersInPolygon();
                    this.map.fitBounds(this.polygon.getBounds());
                });
            });
        },
    },
    watch: {
        view(view) {
            console.log(google);
            if (google !== undefined && view == 'map') {
                if (this.map !== null) {
                    google.maps.event.trigger(this.map, 'resize');

                    this.map.fitBounds(this.bounds);
                }
            }
        },
    },
};



