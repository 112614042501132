import { isEmpty } from '~/resources/js/libs/helpers';
import { mapActions } from 'vuex';
import { map } from '~/resources/js/libs/mixins';

export default {
	name: 'so-map',
	template: '#so-map-template',
	mixins: [map],
	delimiters: ['[[', ']]'],
	props: {
		heading: {
			type: Number,
			default: 0,
		},
		iconPath: {
			type: String,
			required: true,
		},
		initialiseCallback: {
			type: String,
			default: '',
		},
		latitude: {
			default: 0,
		},
		longitude: {
			default: 0,
		},
		markerTitle: {
			type: String,
			default: '',
		},
		pitch: {
			type: Number,
			default: 0,
		},
		provider: {
			type: String,
			default: 'leaflet',
		},
		streetView: {
			type: Boolean,
			default: false,
		},
		streetViewControl: {
			type: Boolean,
			default: true,
		},
		zoom: {
			type: Number,
			default: 16,
		},
	},
	data() {
		return {
			fill: '#ff776b',
			loading: true,
			map: null,
			mapElement: null,
			mapMarker: null,
			stroke: '#cc6056',
		};
	},
	mounted() {
		this.mapElement = this.$el;
	},
	methods: {
		...mapActions('maps', {
			initialiseMaps: 'initialiseMaps',
		}),

		/**
		 * Create a map using Google Maps
		 */
		createMapForGoogle() {
			this.map = new google.maps.Map(this.mapElement);

			let latlng = new google.maps.LatLng(this.latitude, this.longitude);

			let mapOptions = {
				center: latlng,
				mapTypeControl: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				rotateControl: false,
				scaleControl: false,
				zoom: this.zoom,
				zoomControl: true,
			};

			if (!this.streetViewControl) {
				mapOptions.streetViewControl = false;
			}

			this.map.setOptions(mapOptions);

			// Set up street view
			let panorama = null;

			if (this.streetView) {
				panorama = new google.maps.StreetViewPanorama(this.mapElement, {
					position: latlng,
					pov: {
						heading: this.heading,
						pitch: this.pitch,
					},
				});

				this.map.setStreetView(panorama);
			}

			// Set base marker options
			let markerOptions = {
				position: latlng,
			};

			if (!isEmpty(this.markerTitle)) {
				markerOptions.title = this.markerTitle;
			}

			if (this.streetView) {
				markerOptions.map = panorama;
			} else {
				markerOptions.map = this.map;

				// Set marker icon options
				markerOptions.icon = {
					anchor: new google.maps.Point(16, 32),
					animation: google.maps.Animation.DROP,
					fillColor: this.fill,
					fillOpacity: 1,
					path:
						'M16 0c-5.9 0-12 4.5-12 12 0 7.1 10.8 18.2 11.3 18.7.2.2.4.3.7.3s.5-.1.7-.3c.5-.5 11.3-11.5 11.3-18.7 0-7.5-6.1-12-12-12zm0 16c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z',
					scale: 1,
					strokeColor: this.stroke,
					strokeWeight: 1,
				};
			}

			// Create marker
			this.mapMarker = new google.maps.Marker(markerOptions);

			// Update the map when the screen resizes
			google.maps.event.addDomListener(window, 'resize', () => {
				this.map.setCenter(this.latlng);
			});
		},

		/**
		 * Create a map using Leaflet
		 */
		createMapForLeaflet() {
			this.map = L.map(this.mapElement).setView([this.latitude, this.longitude], this.zoom);

			// Tiles
			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
			}).addTo(this.map);

			let icon = L.icon({
				iconUrl: this.getIconPath('pin'),
				iconSize: [27, 34],
				iconAnchor: [13.5, 34],
				popupAnchor: [0, -36],
			});

			// Marker
			this.mapMarker = L.marker([this.latitude, this.longitude], { icon }).addTo(this.map);
		},

		/**
		 * Create a map using Leaflet
		 */
		createMapForMapbox() {
			mapboxgl.accessToken = this.mapsApiKey;

			// Initialise Mapbox map
			this.map = new mapboxgl.Map({
				attributionControl: false,
				center: [this.longitude, this.latitude],
				container: this.mapElement,
				style: 'mapbox://styles/mapbox/streets-v10',
				zoom: this.zoom,
			});

			// Add compact variant of attribution
			this.map.addControl(
				new mapboxgl.AttributionControl({
					compact: true,
				})
			);

			// Add navigation
			let nav = new mapboxgl.NavigationControl();

			this.map.addControl(nav, 'top-left');

			// Add marker
			this.mapMarker = new mapboxgl.Marker({
				anchor: 'bottom',
				color: this.fill,
			})
				.setLngLat([this.longitude, this.latitude])
				.addTo(this.map);
		},

		/**
		 * Initialise maps
		 */
		init() {
			if (this.initialisedMaps) {
				return;
			}

			// Notify other maps that script has loaded
			this.initialiseMaps();

			this.initialisedMaps = true;

			if (this.provider == 'google') {
				this.createMapForGoogle();
			} else if (this.provider == 'mapbox') {
				this.createMapForMapbox();
			} else {
				this.createMapForLeaflet();
			}

			// Update loading screen
			this.loading = false;

			// Call parent function to run when this map is initialised
			const callback = this.$parent[this.initialiseCallback];

			if (typeof callback === 'function') {
				callback();
			}
		},
	},
};
