import { find } from 'lodash';
import { fallback, getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import Language from '~/resources/js/libs/language';
import axios from 'axios';

export default {
	name: 'so-market-appraisal',
	delimiters: ['[[', ']]'],
	props: {
		defaultOffice: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			apiNamespace: 'valuation',
			errorMessage: '',
			fetchedOffices: false,
			fetchedSlots: false,
			offices: [],
			params: null,
			selectedOffice: '',
			selectedSlot: '',
			slots: [],
			submitted: false,
		};
	},
	created() {
		this.fetchOffices();
	},
	computed: {
		/**
		 * The email address of the selected office
		 */
		selectedOfficeEmail() {
			let email = '';

			if (!isEmpty(this.selectedOffice)) {
				if (this.selectedOffice.Email !== undefined && !isEmpty(this.selectedOffice.Email)) {
					email = this.selectedOffice.Email;
				}
			}

			return email;
		},
	},
	methods: {
		/**
		 * Mark an appointment as selected
		 * @param  {object}  appointment  The appointment data
		 */
		deselectAppointment(appointment) {
			this.selectedSlot = '';

			this.reserveSlot(appointment, 'delete');
		},

		/**
		 * Get offices for the current agent
		 */
		fetchOffices() {
			const url = getApiUrl('offices', this.apiNamespace);

			if (!isEmpty(url)) {
				axios
					.get(url)
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.offices = data.offices;

							// Automatically select the only office
							if (this.offices.length == 1) {
								this.setSelectedOffice(this.offices[0]);
							}

							// Select default office, if present
							if (!isEmpty(this.defaultOffice)) {
								let defaultOffice = find(this.offices, office => {
									return office.ID == this.defaultOffice;
								});

								if (defaultOffice !== undefined) {
									this.setSelectedOffice(defaultOffice);
								}
							}
						} else {
							this.errorMessage = fallback(data.error, Language.appraisal.errors.offices);
						}

						this.fetchedOffices = true;
					})
					.catch(error => {
						console.error(url, error);

						this.fetchedOffices = true;
					});
			}
		},

		/**
		 * Get appointments for the current office
		 */
		fetchSlots() {
			const url = getApiUrl('appointment-slots', this.apiNamespace);

			if (!isEmpty(this.selectedOffice) && !isEmpty(url)) {
				axios
					.get(url, {
						params: {
							office: this.selectedOffice.ID,
							interval: 'P1W',
						},
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.slots = data.slots;
						}

						this.fetchedSlots = true;
					})
					.catch(error => {
						console.error(url, error);

						this.fetchedSlots = true;
					});
			}
		},

		/**
		 * Final form submitted via so-ajax-form
		 * @param  {URLSearchParams}  params  The form parameters submitted
		 */
		onFormResponse(params) {
			// Success response
			if (this.$el.querySelectorAll('.response').length) {
				const url = getApiUrl('appointment-book', this.apiNamespace);

				this.submitted = true;
				this.params = params;

				// Send form data for additional processing
				if (!isEmpty(url)) {
					axios.post(url, params);
				}
			}
		},

		/**
		 * Reserve, or free, a slot
		 */
		reserveSlot(appointment, mode) {
			const url = getApiUrl('appointment-reservation', this.apiNamespace);

			axios
				.get(url, {
					params: {
						appointment: appointment.AppointmentID,
						mode: mode,
					},
				})
				.catch(error => {
					console.error(url, error);
				});
		},

		/**
		 * When selecting another office, reset slots
		 */
		resetOffice() {
			this.deselectAppointment(this.selectedSlot);

			this.selectedOffice = '';
			this.slots = [];
			this.fetchedSlots = false;
		},

		/**
		 * Mark an appointment as selected
		 * @param  {object}  appointment  The appointment data
		 */
		selectAppointment(appointment) {
			this.selectedSlot = appointment;

			this.reserveSlot(appointment, 'add');
		},

		/**
		 * Mark the given office as selected
		 * @param  {object}  office  The office details
		 */
		setSelectedOffice(office) {
			this.selectedOffice = Object.freeze(office);
		},
	},
	watch: {
		selectedOffice: {
			handler(office) {
				if (!isEmpty(office.ID)) {
					this.fetchSlots();
				}
			},
			deep: true,
		},
	},
};
