/**
 * number-helpers.js
 * Number helper functions
 *
 * @author     Lewis Howles
 * @copyright  Internet Solutions Services Ltd
 */

export function booleanToInt(boolean) {
	if (boolean) {
		return 1;
	} else {
		return 0;
	}
}

/**
 * Format a figure as a currency
 * @param   {string}  figure    The figure to format
 * @param   {string}  currency  The mask to remove
 * @return  {string}            The trimmed string
 */
export function formatCurrency(figure, currency) {
	let localeString = 'en-GB';

	if (currency === undefined) {
		currency = 'GBP';
	}

	if (currency == 'EUR') {
		localeString = 'de-DE';
	}

	let outputSettings = {
		style: 'currency',
		currency: currency,
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	};

	if (Number.isInteger(figure)) {
		outputSettings.minimumFractionDigits = 0;
	}

	return Number(figure).toLocaleString(localeString, outputSettings);
}

/**
 * Create an array of numbers with the given start and end
 * @param  {int}  start  The starting point
 * @param  {int}  end  The ending point
 */
export function range(start, end) {
	return Array.from(new Array(end), (x, i) => i + start);
}
