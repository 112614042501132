import { getApiUrl, isEmpty } from '~/resources/js/libs/helpers';
import axios from 'axios';

export default {
	name: 'so-property-results-count',
	template: '#so-property-results-count-template',
	delimiters: ['[[', ']]'],
	props: {
		terms: {
			type: Object,
		},
	},
	data() {
		return {
			apiNamespace: 'property/search',
			count: null,
			loading: false,
		};
	},
	methods: {
		/**
		 * Fetch updated count using passed terms as parameters
		 */
		getCount() {
			const url = getApiUrl('property-results-count', this.apiNamespace);

			if (!isEmpty(url)) {
				this.loading = true;

				let terms = this.terms;

				// We cannot use class as a property name
				if (terms.propertyClass !== undefined) {
					terms.class = terms.propertyClass;

					delete terms.propertyClass;
				}

				axios
					.get(url, {
						params: this.terms,
					})
					.then(({ data }) => {
						if (data && data.status === 'success') {
							this.count = data.number_of_results;
						} else {
							this.count = null;
						}

						this.loading = false;
					})
					.catch(error => {
						this.loading = false;

						console.error(url, error);
					});
			}
		},
	},
	watch: {
		terms: {
			deep: true,
			handler() {
				this.getCount();
			},
		},
	},
};
