import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { checkCookie, isEmpty, loadScript } from '~/resources/js/libs/helpers';

/**
 * Load The Google Maps and initialise self when ready
 */
let map = {
	data() {
		return {
			google: {
				requiresApiKey: true,
				isGoogle: true,
				baseMapsUrl: 'https://maps.google.com/maps/api/js',
			},
			initialisedMaps: false,
			leaflet: {
				requiresApiKey: false,
				isGoogle: false,
				baseMapsUrl: 'https://unpkg.com/leaflet@1.5.1/dist/leaflet.js',
			},
			mapbox: {
				requiresApiKey: true,
				isGoogle: false,
				baseMapsUrl: 'https://api.mapbox.com/mapbox-gl-js/v1.11.1/mapbox-gl.js',
			},
			mapError: false,
			mapsApiKey: '',
		};
	},
	computed: {
		...mapState('maps', {
			mapScriptNeeded: 'mapScriptNeeded',
			mapsReady: 'mapsReady',
		}),
	},
	created() {
		// Initialise key from CMSMS
		this.mapsApiKey = constants.MAPS_API_KEY;

		this.loadMaps();
	},
	methods: {
		...mapActions('maps', {
			initialiseMaps: 'initialiseMaps',
			mapsNoLongerNeeded: 'mapsNoLongerNeeded',
		}),

		/**
		 * Delete current Google Maps markers
		 */
		deleteMarkersForGoogle() {
			this.markers.forEach(marker => {
				marker.setMap(null);
			});

			this.markers = [];
		},

		/**
		 * Delete current leaflet maps markers
		 */
		deleteMarkersForLeaflet() {
			this.markers.forEach(marker => {
				this.map.removeLayer(marker);
			});

			this.markers = [];
		},

		/**
		 * Delete current Mapbox markers
		 */
		deleteMarkersForMapbox() {
			this.markers.forEach(marker => {
				marker.remove();
			});

			this.markers = [];
		},

		/**
		 * Return an SVG icon path
		 * @param   {string}  icon  The icon filename
		 * @return  {string}        The image path
		 */
		getIconPath(icon) {
			return `${this.iconPath}${icon}.svg`;
		},

		/**
		 * Load map script, if required
		 */
		loadMaps() {
			if (!checkCookie('maps')) {
				return;
			}

			let provider = this[this.provider];

			if (provider.requiresApiKey && isEmpty(this.mapsApiKey)) {
				this.mapError = true;

				throw new Error(`API Key missing for provider ${this.provider}`);
			}

			// Only load script if not present
			if (this.mapScriptNeeded) {
				// Tell store that the script has been loaded, so we don't need to load it again
				this.mapsNoLongerNeeded();

				let url = provider.baseMapsUrl;

				if (provider.isGoogle == true) {
					url = `${url}?key=${this.mapsApiKey}&libraries=drawing`;
				}

				// Insert map script
				loadScript(url, this.initialiseMaps);
			} else if (this.mapsReady && checkCookie('maps')) {
				// If script already present, move on to init
				this.initialiseMaps();
			}
		},
	},
	watch: {
		mapsReady(ready) {
			if (ready && checkCookie('maps')) {
				if (typeof this.init == 'function') {
					this.init();
				}
			}
		},
	},
};

/**
 * Allow a popup to be shown and hidden
 */
let popup = {
	data() {
		return {
			popupActive: false,
		};
	},
	mounted() {
		document.body.addEventListener('keydown', this.deactivatePopup, false);
	},
	beforeDestroy() {
		document.body.removeEventListener('keydown', this.deactivatePopup, false);
	},
	methods: {
		/**
		 * Deactivate a popup if watched keys are pressed
		 * @param  {object}  event  The event details
		 */
		deactivatePopup(event) {
			// Deactivate popup when pressing escape
			if (this.popupActive && event.key == 'Escape') {
				this.popupActive = false;
			}
		},

		/**
		 * Toggle active state of the popup
		 */
		togglePopupActive() {
			this.popupActive = !this.popupActive;

			Vue.nextTick(() => {
				// Scroll back to the top of the overflow
				let overflow = this.$el.querySelector('.popup__overflow');

				if (overflow) {
					overflow.scrollTop = 0;
				}
			});
		},
	},
};

/**
 * Keep track of the current window size for responsive reformatting
 */
let responsive = {
	data() {
		return {
			windowWidth: window.innerWidth,
		};
	},
	mounted() {
		let timer;

		window.onresize = () => {
			clearTimeout(timer);

			timer = setTimeout(() => {
				this.windowWidth = window.innerWidth;
			}, 150);
		};
	},
};

/**
 * Allow toggle of this component by another component
 */
let toggleable = {
	props: {
		componentName: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			toggleVisible: false,
		};
	},
	computed: {
		...mapState('misc', {
			toggle: 'toggle',
		}),
	},
	watch: {
		toggle(toggle) {
			if (toggle.componentName !== this.componentName) {
				return;
			}

			this.toggleVisible = toggle.visible;
		},
	},
};

export { map, popup, responsive, toggleable };
