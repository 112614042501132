import { responsive } from '~/resources/js/libs/mixins';
import Wallop from 'Wallop';

export default {
	name: 'so-slider',
	mixins: [responsive],
	delimiters: ['[[', ']]'],
	props: {
		autoPlay: {
			type: Boolean,
			default: false,
		},
		autoPlayDuration: {
			type: Number,
			default: 4500,
		},
		autoPlayInitialDelay: {
			type: Number,
			default: 0,
		},
		equalHeight: {
			type: Boolean,
			default: false,
		},
		keyboardNavigation: {
			type: Boolean,
			default: true,
		},
		pauseOnHover: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			dots: [],
			index: 0,
			maxHeight: 0,
			slides: [],
			timeout: null,
			wallop: null,
			wallopSettings: {
				buttonPreviousClass: 'slider-navigation--previous',
				buttonNextClass: 'slider-navigation--next',
				itemClass: 'slider__slide',
				currentItemClass: 'slider__slide--current',
				showPreviousClass: 'slider__slide--show-previous',
				showNextClass: 'slider__slide--show-next',
				hidePreviousClass: 'slider__slide--hide-previous',
				hideNextClass: 'slider__slide--hide-next',
			},
		};
	},
	mounted() {
		this.wallop = new Wallop(this.$el, this.wallopSettings);

		this.slides = this.$el.getElementsByClassName(this.wallopSettings.itemClass);

		if (this.autoPlayInitialDelay > 0) {
			for (let i = 0; i < this.slides.length; i++) {
				this.slides[i].style.animationDelay = `${this.autoPlayInitialDelay}ms`;
			}
		}

		// Autoplay timer
		if (this.autoPlay) {
			// Allow for an initial pause to offset sliding with siblings
			this.timeout = setTimeout(() => {
				this.loadNext();
			}, this.autoPlayDuration);
		}

		// Monitor changes in slides
		this.wallop.on('change', event => {
			if (this.autoPlay) {
				// Restart timeout when current item is changed
				clearTimeout(this.timeout);

				this.timeout = setTimeout(() => {
					this.loadNext();
				}, this.autoPlayDuration);
			}

			// Track current item
			this.index = event.detail.currentItemIndex;
		});

		// Pause slider when hovering with mouse
		if (this.pauseOnHover) {
			this.$el.addEventListener('mouseenter', () => {
				clearTimeout(this.timeout);
			});

			this.$el.addEventListener('mouseleave', () => {
				this.timeout = setTimeout(() => {
					this.loadNext();
				}, this.autoPlayDuration);
			});
		}

		// Add Left / Right arrow navigation to slider
		if (this.keyboardNavigation) {
			document.addEventListener('keydown', event => {
				if (event.keyCode == '37') {
					// Left
					this.wallop.previous();
				} else if (event.keyCode == '39') {
					// Right
					this.wallop.next();
				}
			});
		}

		// Equal height slides
		if (this.equalHeight) {
			this.setSlideHeight();
		}

		// Activate dots
		this.dots = this.$el.getElementsByClassName('slider__dot');

		if (this.dots.length) {
			this.initialiseDots();
		}
	},
	methods: {
		/**
		 * Go to a particular slide
		 */
		goTo(index) {
			this.wallop.goTo(index);
		},

		/**
		 * Attach events to navigation dots, and highlight the dot representing the current item
		 */
		initialiseDots() {
			let dots = this.$el.getElementsByClassName('slider__dot');

			// Reset minimum heights of slides
			for (let i = 0; i < dots.length; i++) {
				dots[i].addEventListener('click', () => {
					this.wallop.goTo(i);
				});
			}

			// Listen to wallop change and update classes
			this.wallop.on('change', event => {
				this.$el
					.getElementsByClassName('slider__dot--current')[0]
					.classList.remove('slider__dot--current');
				this.dots[event.detail.currentItemIndex].classList.add('slider__dot--current');
			});
		},

		/**
		 * Load the next item in the slider
		 */
		loadPrevious() {
			let previousIndex = (this.wallop.currentItemIndex - 1) % this.wallop.allItemsArray.length;

			this.wallop.goTo(previousIndex);
		},

		/**
		 * Load the next item in the slider
		 */
		loadNext() {
			let nextIndex = (this.wallop.currentItemIndex + 1) % this.wallop.allItemsArray.length;

			this.wallop.goTo(nextIndex);
		},

		/**
		 * Find the maximum height slide and set all slides to the same height
		 */
		setSlideHeight() {
			let maxHeight = 0;

			// Reset minimum heights of slides
			for (let i = 0; i < this.slides.length; i++) {
				this.slides[i].style.minHeight = 0;
			}

			for (let i = 0; i < this.slides.length; i++) {
				let height = this.slides[i].offsetHeight;

				if (height > maxHeight) {
					maxHeight = height;
				}
			}

			for (let i = 0; i < this.slides.length; i++) {
				this.slides[i].style.minHeight = `${maxHeight / 16}em`;
			}

			this.maxHeight = maxHeight;
		},
	},
	watch: {
		windowWidth() {
			this.setSlideHeight();
		},
	},
};
