import QRious from 'qrious';

export default {
	name: 'so-qr',
	template: '#so-qr-template',
	props: {
		background: {
			type: String,
			default: '#ffffff',
		},
		foreground: {
			type: String,
			default: '#000000',
		},
		size: {
			type: String,
			default: '100',
		},
		value: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			qr: null,
		};
	},
	computed: {
		alt() {
			return `QR code for ${this.value}`;
		},
	},
	mounted() {
		this.qr = new QRious({
			background: this.background,
			element: this.$el,
			foreground: this.foreground,
			size: this.size,
			value: this.value,
			padding: null,
		});
	},
};
