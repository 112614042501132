export default {
	name: 'so-local-area-transit',
	template: '#so-local-area-transit-template',
	delimiters: ['[[', ']]'],
	props: {
		stop: {
			type: Object,
			required: true,
		},
	},
};
