/**
 * Application.js
 * App initialisation
 *
 * @author     Paul Spann
 * @copyright  Internet Solutions Services Ltd
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import './bootstrap';

import Vue from 'vue';

// Global state storage
import store from './store';

window.store = store;

/**
 * ------------------------------------------------------------
 * Filters
 * ------------------------------------------------------------
 **/

// Allow converting a boolean to a string
import FilterBoolToString from './filters/bool-to-string';

Vue.filter('boolToString', FilterBoolToString);

// Format a number as a currency
import FilterCurrency from './filters/currency';

Vue.filter('currency', FilterCurrency);

// Join an array with optional glue
import FilterJoin from './filters/join';

Vue.filter('join', FilterJoin);

// Transform a string into something suitable for an HTML ID
import FilterMakeId from './filters/make-id';

Vue.filter('makeId', FilterMakeId);

/**
 * ------------------------------------------------------------
 * Directives
 * ------------------------------------------------------------
 **/

// Add Pikaday date picker to elements
import DirectivePikaday from './directives/pikaday';

Vue.directive('pikaday', DirectivePikaday);

/**
 * ------------------------------------------------------------
 * Components
 * ------------------------------------------------------------
 **/

/**
 * Plugins
 */

import ComponentMultiselect from 'vue-multiselect';

Vue.component('so-multiselect', ComponentMultiselect);

/**
 * Base (dumb) components
 * Base components perform simple tasks without
 * having to import the same functions into multiple components
 */

// Format a number as a currency
import BaseAppCurrency from './components/base/app-currency';

Vue.component('app-currency', BaseAppCurrency);

/**
 * Miscellaneous
 */

// Allow a button to activate a tab on the current page
import ComponentSoActivateTab from './components/so-activate-tab';

Vue.component('so-activate-tab', ComponentSoActivateTab);

// Capture submission of a form and send via Ajax using FormData
import ComponentSoAjaxForm from './components/so-ajax-form';

Vue.component('so-ajax-form', ComponentSoAjaxForm);

// Alert message components
// - Type is one of 'info' (default), 'error' or 'success'
// - Message automatically fades, unless 'type' is 'error' or 'important' is true
// - If 'type' is 'error', close button appears
// - If 'important' is true, message cannot be closed
import ComponentSoAlert from './components/so-alert';

Vue.component('so-alert', ComponentSoAlert);

// Allow autocomplete completion from external resource
import ComponentSoAutocomplete from './components/so-autocomplete';

Vue.component('so-autocomplete', ComponentSoAutocomplete);

// Display cookie notice
import ComponentSoCookieNotice from './components/so-cookie-notice';

Vue.component('so-cookie-notice', ComponentSoCookieNotice);

import ComponentSoCookieToggle from './components/so-cookie-toggle';

Vue.component('so-cookie-toggle', ComponentSoCookieToggle);

// Allow a Google Map to be inserted
import ComponentSoMap from './components/so-map';

Vue.component('so-map', ComponentSoMap);

// Navigation
// - All interaction is based on 'open' data - e.g. navigation.open = true
// - SVG path retrieved from root instance
import ComponentSoNavigation from './components/so-navigation';

Vue.component('so-navigation', ComponentSoNavigation);

// Facilitate simple PayPal redirect purchasing, with automatic calculation of total cost
//import ComponentSoPaypalPurchase from './components/so-paypal-purchase';

//Vue.component('so-paypal-purchase', ComponentSoPaypalPurchase);

// Display a placeholder for items blocked by privacy settings, and allow the placeholder to open those settings
import ComponentSoPrivacyPlaceholder from './components/so-privacy-placeholder';

Vue.component('so-privacy-placeholder', ComponentSoPrivacyPlaceholder);

// Buttons that react to user input, providing feedback, and optionally running a callback function
import ComponentSoReactive from './components/so-reactive';

Vue.component('so-reactive', ComponentSoReactive);

// Pagination
import ComponentSoPagination from './components/so-pagination';

Vue.component('so-pagination', ComponentSoPagination);

// QR code generation
import ComponentSoQR from './components/so-qr';

Vue.component('so-qr', ComponentSoQR);

// Slider component - requires inline template
import ComponentSoSlider from './components/so-slider';

Vue.component('so-slider', ComponentSoSlider);

// Create tabs and tab panels
import ComponentSoTabs from './components/so-tabs';

Vue.component('so-tabs', ComponentSoTabs);

import ComponentSoTab from './components/so-tab';

Vue.component('so-tab', ComponentSoTab);

// Toggle element visibility
import ComponentSoToggleComponent from './components/so-toggle-component';

Vue.component('so-toggle-component', ComponentSoToggleComponent);

import ComponentSoToggleable from './components/so-toggleable';

Vue.component('so-toggleable', ComponentSoToggleable);

/**
 * Contact
 */

// Display transactions for an agreement
import ComponentSoAgreementTransactions from './components/account/contact/so-agreement-transactions';

Vue.component('so-agreement-transactions', ComponentSoAgreementTransactions);

// Display a contact's rental agreements
import ComponentSoAgreements from './components/account/contact/so-agreements';

Vue.component('so-agreements', ComponentSoAgreements);

// Wrapper for user account
import ComponentSoUserAccount from './components/account/so-user-account';

Vue.component('so-user-account', ComponentSoUserAccount);

/**
 * Purchaser / Tenant
 */

// Live matches, based on current match requirements
import ComponentSoLiveMatches from './components/account/purchaser/so-live-matches';

Vue.component('so-live-matches', ComponentSoLiveMatches);

// Allow a customer to create and edit requirements
import ComponentSoMatchRequirements from './components/account/purchaser/so-match-requirements';

Vue.component('so-match-requirements', ComponentSoMatchRequirements);

// Allow a tenant to view and report repairs
import ComponentSoRepairsList from './components/account/tenant/so-repairs-list';
import ComponentSoRepair from './components/account/tenant/so-repair';
import ComponentSoReportRepair from './components/account/tenant/so-report-repair';

Vue.component('so-repairs-list', ComponentSoRepairsList);
Vue.component('so-repair', ComponentSoRepair);
Vue.component('so-report-repair', ComponentSoReportRepair);

// Save a property or remove a property from saved list
import ComponentSoSaveProperty from './components/account/purchaser/so-save-property';

Vue.component('so-save-property', ComponentSoSaveProperty);

/**
 * Owners
 */

// Market appraisal appointment booking
import ComponentSoMarketAppraisal from './components/account/owner/so-market-appraisal';

Vue.component('so-market-appraisal', ComponentSoMarketAppraisal);

/**
 * Property
 */

// Display EPC
import ComponentSoEpc from './components/property/so-epc';

Vue.component('so-epc', ComponentSoEpc);

// Get an instant valuation estimate given basic property details
import ComponentSoInstantValuation from './components/property/so-instant-valuation';

Vue.component('so-instant-valuation', ComponentSoInstantValuation);

// Local area information
import ComponentSoLocalAreaInformation from './components/property/so-local-area-information';

Vue.component('so-local-area-information', ComponentSoLocalAreaInformation);

// Local area map
import ComponentSoLocalAreaMap from './components/property/so-local-area-map';

Vue.component('so-local-area-map', ComponentSoLocalAreaMap);

// Local area mini
import ComponentSoLocalAreaMini from './components/property/so-local-area-mini';

Vue.component('so-local-area-mini', ComponentSoLocalAreaMini);

// Local healthcare
import ComponentSoLocalAreaHealthcare from './components/property/so-local-area-healthcare';

Vue.component('so-local-area-healthcare', ComponentSoLocalAreaHealthcare);

// Local school
import ComponentSoLocalAreaSchool from './components/property/so-local-area-school';

Vue.component('so-local-area-school', ComponentSoLocalAreaSchool);

// Local transit
import ComponentSoLocalAreaTransit from './components/property/so-local-area-transit';

Vue.component('so-local-area-transit', ComponentSoLocalAreaTransit);

// Market stats
import ComponentSoMarketStats from './components/property/so-market-stats';

Vue.component('so-market-stats', ComponentSoMarketStats);

// Mortgage calculator
import ComponentSoMortgageCalculator from './components/property/so-mortgage-calculator';

Vue.component('so-mortgage-calculator', ComponentSoMortgageCalculator);

// Price trends
import ComponentSoPriceTrends from './components/property/so-price-trends';

Vue.component('so-price-trends', ComponentSoPriceTrends);

// Individual property interactions
import ComponentSoProperty from './components/property/so-property';

Vue.component('so-property', ComponentSoProperty);

// Allow switching photograph size
import ComponentSoPropertyPhotographs from './components/property/so-property-photographs';

Vue.component('so-property-photographs', ComponentSoPropertyPhotographs);

// Allow interaction with property results
import ComponentSoPropertyResults from './components/property/so-property-results';

Vue.component('so-property-results', ComponentSoPropertyResults);

import ComponentSoPropertyResultsCount from './components/property/so-property-results-count';

Vue.component('so-property-results-count', ComponentSoPropertyResultsCount);

import ComponentSoPropertyResultsMap from './components/property/so-property-results-map';

Vue.component('so-property-results-map', ComponentSoPropertyResultsMap);

// Allows custom property search form to be generated based on current state
import ComponentSoPropertySearch from './components/property/so-property-search';

Vue.component('so-property-search', ComponentSoPropertySearch);

// Sold properties
import ComponentSoSoldProperties from './components/property/so-sold-properties';

Vue.component('so-sold-properties', ComponentSoSoldProperties);

// Display a stamp duty estimate
import ComponentSoStampDuty from './components/property/so-stamp-duty';

Vue.component('so-stamp-duty', ComponentSoStampDuty);

// Allow selecting property type / style
import ComponentSoTypesStyles from './components/property/so-types-styles';

Vue.component('so-types-styles', ComponentSoTypesStyles);

/**
 * Forms
 */

// Use a checkbox to control a hidden field
import ComponentSoCheckbox from './components/forms/so-checkbox';

Vue.component('so-checkbox', ComponentSoCheckbox);

// Use a select box to control a hidden field
import ComponentSoSelect from './components/forms/so-select';

Vue.component('so-select', ComponentSoSelect);

// Use Stand Out Property Mananger to find address from postcode
import ComponentSoPostcodeLookup from './components/forms/so-postcode-lookup';

Vue.component('so-postcode-lookup', ComponentSoPostcodeLookup);

/**
 * ------------------------------------------------------------
 * Boot
 * ------------------------------------------------------------
 **/

// Global Vue binding
import { mapActions } from 'vuex';

new Vue({
	el: '#app',
	store,
	delimiters: ['[[', ']]'],
	created() {
		// Put URL vars into storage for use by components
		this.updateUrlVars();
	},
	mounted() {
		// Refresh lazyload
		window.LazyLoadInstance.update();

		// Set blurred class on field elements
		this.activateBlurred();
	},
	methods: {
		...mapActions('misc', {
			updateUrlVars: 'updateUrlVars',
		}),

		/**
		 * Add a blurred class to form elements on blur, allowing invalid styling to only apply after user input has occurred
		 */
		activateBlurred() {
			let fields = this.$el.querySelectorAll('input, select, textarea');

			for (let i = 0; i < fields.length; ++i) {
				let field = fields[i];

				if (field.type !== 'hidden') {
					field.addEventListener('blur', this.addBlurredClass);
				}
			}
		},

		/**
		 * Add a blurred class, or revmove a blur handler if already present
		 * @param  {object}  event  The blur event
		 */
		addBlurredClass(event) {
			if (event.target.classList.contains('form__blurred')) {
				event.target.removeEventListener('blur', this.addBlurredClass);
			} else {
				event.target.classList.add('form__blurred');
			}
		},
	},
});
