import { mapState, mapActions } from 'vuex';
import { checkCookie, expireCookie, setCookie } from '~/resources/js/libs/helpers';

export default {
	name: 'so-cookie-notice',
	template: '#so-cookie-notice-template',
	delimiters: ['[[', ']]'],
	data() {
		return {
			active: true,
			cookieName: 'cookie-notice',
			hasChanged: false,
		};
	},
	computed: {
		...mapState('cookie', {
			cookieNoticeActive: 'cookieNoticeActive',
		}),

		refreshLink() {
			return location.href;
		},
	},
	created() {
		// Check for cookie... cookie existence
		if (checkCookie(this.cookieName)) {
			this.active = false;
		} else {
			// Update store
			this.updateCookieNoticeActive(this.active);
		}
	},
	methods: {
		...mapActions('cookie', {
			updateCookieNoticeActive: 'updateCookieNoticeActive',
		}),

		/**
		 * Activate popup for cookie changes
		 */
		activate() {
			this.active = true;
		},

		/**
		 * Display a notice to the user reminding them to refresh
		 * their screen to re-load content after changing a preference
		 */
		changed() {
			this.hasChanged = true;
		},

		/**
		 * Close the active popup
		 */
		closePopup() {
			this.active = false;
		},
	},
	watch: {
		active(active) {
			if (active) {
				expireCookie(this.cookieName);
			} else {
				setCookie(this.cookieName, 365);
			}

			// Update store
			this.updateCookieNoticeActive(active);
		},

		cookieNoticeActive(shouldActivate) {
			if (shouldActivate) {
				this.active = true;
			}
		},
	},
};
